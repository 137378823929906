import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false
import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
Vue.prototype.$wow = wow
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper);
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Element)
import VueCountUp from 'vue-countupjs'
Vue.use(VueCountUp)
Vue.component('VueCountUp', VueCountUp) //全局注册组件
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
