import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'
import aboutView from '../views/about.vue'
import joinView from '../views/join.vue'
import businessView from '../views/business.vue'
import caseView from '../views/case.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: aboutView
  },
  {
    path: '/join',
    name: 'join',
    component: joinView
  },
  {
    path: '/business',
    name: 'business',
    component: businessView
  },
  {
    path: '/case',
    name: 'case',
    component: caseView
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})
// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
