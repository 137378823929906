<template>
  <div style="background:#f2f2f2;">
    <header ref="headers" :class="{ 'bj': isHeaderFixed }">
      <div class="header wid1800">
        <div class="logo">
          <img src="https://oss.hzwohu.com/assets/logo.png" alt="logo">
        </div>
        <div class="header-nav">
          <div class="header-nav-li" @click="goIndex">首页</div>
          <div class="header-nav-li" @click="goTopage('about')">关于沃虎</div>
          <div class="header-nav-li" @click="goTopage('business')">公司业务</div>
          <div class="header-nav-li on">联系我们</div>
          <div class="header-nav-li header-nav-join">
            加入我们
            <div class="header-b header-k jianli">
              <div class="header-k2 fnt36 fm-APT-R">
                简历投递请联系
              </div>
              <div class="header-k3 fm-APT-R" style="font-size:15px;">
                HRBP 涂女士：18106511396（手机和微信同号）<br>
                <a href="https://www.zhipin.com/gongsi/job/0abc679d53ebe2151nV72dW8EA~~.html?ka=all-jobs-hot" target="_blank">人才通道</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="join-content">
      <div class="imgBj pos pc-show">
      </div>
      <div class="join-text">
        <div class="p1 fm-APT-M">联系我们</div>
        <div class="p2 fm-APT-R">追梦前行 互相成就</div>
      </div>
      <a @click="handleAnchorClick('#section2')">
        <i class="gd"> <img src="https://oss.hzwohu.com/assets/banner/gd.png" alt=""> </i>
      </a>
    </div>
    <div id="section2">
      <div class="join-subtitle wow fadeInUp animated">
        <h2>联系我们</h2>
        <h3><span>CONTACT US</span></h3>
      </div>
      <div class="contact-detail">
        <div class="card">
          <h3 class="wow fadeInUp animated">杭州沃虎科技有限公司</h3>
          <p class="wow fadeInUp animated">
            <img src="https://oss.hzwohu.com/assets/add.png" alt="">
            浙江省杭州市滨江区春风大楼8幢17楼</p>
          <p class="wow fadeInUp animated">
            <img src="https://oss.hzwohu.com/assets/tel.png" alt="">
            17681845470</p>

        </div>
        <div class="address wow fadeInUp animated">
          <div class="address-tips">杭州沃虎科技有限公司</div>
        </div>
      </div>
    </div>
    <Footers></Footers>
  </div>
</template>

<script>
  import $ from 'jquery'; // 引入jQuery库
  import Footers from "@/components/footers";
  export default {
    components: {
      Footers,
    },
    data() {
      return {
        visiblePartners: [],
        showMoreButton: true,
        partnersPerLoad: 8,
        currentIndex: 0,
        scrollDistance: 0,
        isHeaderFixed: false,
      };
    },
    created() {
      // this.loadPartners();
    },
    mounted() {
      const $headerNavLi2 = $('.header-nav-join');
      const $headerB = $headerNavLi2.find('.header-b');

      // 使用jQuery绑定事件
      $headerNavLi2.hover(
        function () {
          $headerB.stop().slideDown();
        },
        function () {
          $headerB.stop().slideUp();
        }
      );
      new this.$wow.WOW().init({ //新建实列
        boxClass: 'wow', //class名字
        animateClass: 'animate__animated', //animateclass动画库类名,和当前animate动画库决定
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true //对异步加载的内容进行操作（默认为true）
      });
      window.addEventListener('scroll', this.handleScroll);

    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      goTopage(val) {
        this.$router.push(`/${val}`);
        window.scrollTo(0, 0);
      },
      goIndex() {
        this.$router.push("/");
        window.scrollTo(0, 0);

      },
      handleAnchorClick(anchor) {
        this.$el.querySelector(anchor).scrollIntoView({
          behavior: 'smooth'
        });
      },
      handleScroll() {
        this.scrollDistance = window.scrollY || document.documentElement.scrollTop;
        if (this.scrollDistance > 10) {
          this.isHeaderFixed = true;
        } else {
          this.isHeaderFixed = false;
        }
      },
    },

  };
</script>
<style scoped>
  .header-k {
    width: 52vw;
    left: initial;
    right: 3.125vw;
    background: #fff;
    padding: 2vw 2.3vw;
    overflow: hidden;
  }

  .header-k2 {
    float: left;
    line-height: 1.1;
    padding-bottom: 1.2vw;
    position: relative;
    color: #222;
  }

  .header-b {
    width: 93.75%;
    border-radius: 10px;
    position: absolute;
    top: 100%;
    overflow: hidden;
    display: none;
  }

  .jianli {
    width: 45%;
    display: none;
    height: 112px;
    padding-top: 28.8px;
    margin-top: 0px;
    padding-bottom: 28.8px;
    margin-bottom: 0px;
    box-shadow: 0 5px 10px #ccc;
    font-weight: normal !important;
  }

  .fnt36 {
    font-size: 1.875vw;
  }

  .header-k3 {
    float: right;
    /* text-align: right; */
    line-height: 1.2;
    color: #222;
  }

  .header-k3 a {
    display: inline-block;
    margin-top: 15px;
  }

  .fm-APT-R {
    font-family: Alibaba-PuHuiTi-Regular;
  }

  .fnt30 {
    font-size: 1.5625vw;
  }

  .bj {
    background: #fff;
    border-bottom: 1px solid #ccc;
  }

  header.bj .header-nav {
    color: #222;
    line-height: 5vw;
  }

  header {
    position: fixed;
    z-index: 39;
    width: 100%;
    top: 0;
    left: 0;
  }

  .header {
    transition: all .3s;
    -webkit-transition: all .3s;
  }

  .wid1800 {
    width: 93.75%;
    margin: 0 auto;
  }

  .logo {
    font-size: 0;
    float: left;
    transition: all .3s;
    -webkit-transition: all .3s;
  }

  .logo img {
    width: 10vw;
  }

  .header-nav {
    float: right;
    font-size: 0;
    color: #fff;

  }

  .header-nav-li {
    display: inline-block;
    vertical-align: top;
    vertical-align: top;
    font-size: 0.94vw;
    font-weight: 700;
    line-height: 5vw;
    padding: 0 1.5vw;
    cursor: pointer;
    transition: all .5s;
    -webkit-transition: all .5s;
  }

  .header-nav-li:hover,
  .header-nav-li.on {
    opacity: 1 !important;
    color: #00AAc3 !important;
    transition: all .5s;
    -webkit-transition: all .5s;
  }

  .about-content {
    width: 100%;
    height: 100vh;
    min-height: 45vw;
    position: relative;
    overflow: hidden;
  }

  .banner-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url('https://oss.hzwohu.com/assets/banner/lianxi.jpeg') no-repeat;
    background-size: cover;
  }

  .join-content {
    width: 100%;
    height: 539px;
    position: relative;
    overflow: hidden;
  }

  .imgBj {
    background: no-repeat center;
    background-size: cover;
    background-image: url('https://oss.hzwohu.com/assets/lianxi.jpg');
    /* background-image: url('https://oss.hzwohu.com/assets/banner/lianxi.jpeg'); */
  }

  .imgBj.pos {
    width: 100%;
    height: 539px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .join-text {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
  }

  .join-text .p1 {
    color: #fff;
    font-size: 40px;
    line-height: 1.4;
    font-weight: 700;
  }

  .join-text .p2 {
    color: rgba(255, 255, 255, 0.9);
    font-size: 24px;
    letter-spacing: 2px;
  }

  .join-subtitle h2 {
    font-size: 40px;
    color: #111;
    text-align: center;
    margin: 80px auto 15px;
    font-weight: normal;
  }

  .join-subtitle h3 {
    font-size: 40px;
    color: #111111;
    margin-bottom: 60px;
    text-align: center;
    font-weight: normal;
  }

  .join-subtitle h3 span {
    position: relative;
    display: inline-block;
  }

  .join-subtitle h3 span:after {
    content: '';
    background-color: #e2e2e2;
    width: 230px;
    height: 1px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -27px;
  }

  .contact-detail {
    width: 85%;
    height: 500px;
    position: relative;
    padding-top: 60px;
    margin: 120px auto 180px;
  }

  .contact-detail .card {
    background: #ffffff;
    box-shadow: 3px 2px 26px 0 rgba(0, 0, 0, 0.07);
    width: 48%;
    height: 270px;
    padding-bottom: 40px;
    position: absolute;
    z-index: 10;
    left: 0;
    top: 40px;
  }

  .contact-detail .card h3 {
    margin-bottom: 50px;
  }

  .contact-detail .card h3 {
    font-size: 26px;
    font-weight: normal;
    text-align: center;
    margin: 55px auto 30px;
    border-bottom: 2px solid #00AAc3;
    width: 85%;
    padding-bottom: 35px;
  }

  .contact-detail .card p {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    font-size: 16px;
    width: 78%;
    margin-bottom: 25px;
    margin: 0 auto;
    color: #606060;
    ;
    line-height: 24px;
  }

  .contact-detail .card p img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .contact-detail .address {
    position: relative;
    left: 35%;
    top: 40px;
    z-index: 9;
    overflow: hidden;
    user-select: none;
    width: 700px;
    height: 400px;
    background: url('https://oss.hzwohu.com/assets/address.png') no-repeat;
    background-size: cover;
  }

  .address-tips {
    position: absolute;
    display: inline;
    cursor: inherit;
    border: none;
    padding: 1px;
    white-space: nowrap;
    font: 15px / 48px 微软雅黑;
    z-index: 10661674;
    color: rgb(17, 17, 17);
    height: 70px;
    width: 277px;
    text-align: center;
    background-image: url('https://oss.hzwohu.com/assets/maptext.png');
    background-size: 100%;
    left: 291px;
    top: 135px;
  }

  .join-us-content {
    text-align: center;
  }

  .join-section2-text {
    font-size: 15px;
    line-height: 2.4vw;
  }

  .gd {
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
    animation: dong3 1.5s linear infinite;
  }

  .gd img {
    width: 24px;
  }

  @keyframes dong3 {
    0% {
      transform: translate(-50%, 0);
    }

    50% {
      transform: translate(-50%, 15px);
    }

    100% {
      transform: translate(-50%, 0);
    }
  }
</style>