<template>
  <div>
    <header ref="headers" :class="{ 'bj': isHeaderFixed }">
      <div class="header wid1800">
        <div class="logo">
          <img src="https://oss.hzwohu.com/assets/logo.png" alt="logo">
        </div>
        <div class="header-nav">
          <div class="header-nav-li on">首页</div>
          <div class="header-nav-li" @click="goAbout">关于沃虎</div>
          <div class="header-nav-li" @click="goBusiness">公司业务</div>
          <div class="header-nav-li" @click="goJon">联系我们</div>
          <div class="header-nav-li header-nav-join">
            加入我们
            <div class="header-b header-k jianli">
              <div class="header-k2 fnt36 fm-APT-R">
                简历投递请联系
              </div>
              <div class="header-k3 fm-APT-R" style="font-size:15px;">
                HRBP 涂女士：18106511396（手机和微信同号）<br>
                <a href="https://www.zhipin.com/gongsi/job/0abc679d53ebe2151nV72dW8EA~~.html?ka=all-jobs-hot" target="_blank">人才通道</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="home-top">
      <!-- <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide> -->
      <div class="home-slide-common home-slide1">
        <div class="home-slide-text">
          <p class="fm-APT-M p2 wow fadeIn" data-wow-duration="0.5s" data-wow-delay="0.5s">沃虎科技</p>
          <p class="p2 fm-APT-M wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">
            品质服务和优质内容驱动的综合型科技公司
          </p>
        </div>
      </div>
      <!-- </swiper-slide> -->
      <!-- <swiper-slide>
          <div class="home-slide-common home-slide2">
            <div class="home-slide-text">
              <p class="p2 fm-APT-M">
                多平台、全链路的卓越整合营销服务商
              </p>
            </div>
          </div>
        </swiper-slide> -->
      <!-- </swiper> -->
      <!-- <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div> -->
      <div class="home-banner-bottom">
        <div class="banner-bottom-warp">
          <div class="bottom-warp-item" @click="goBusiness">
            <div class="warp-item_banvdig" style="display: none;opacity: 1;">
              <img src="https://oss.hzwohu.com/assets/yingxiao.svg" alt="">
            </div>
            <div class="warp-item_banvdte">
              <p class="fm-APT-M">品牌营销</p>
            </div>
          </div>
          <div class="bottom-warp-item" @click="goToSlider(1)">
            <div class="warp-item_banvdig" style="display: none;opacity: 1;">
              <img src="https://oss.hzwohu.com/assets/mei.svg" alt="">
            </div>
            <div class="warp-item_banvdte">
              <p class="fm-APT-M">媒介资源</p>
            </div>
          </div>
          <div class="home-ups animated wow fadeInDown" data-wow-delay=".7s">
            <a @click="handleAnchorClick('#sectionHome')">
              <div class="about-d"><img src="https://oss.hzwohu.com/assets/banner/ico16.png"></div>
            </a>
          </div>
          <div class="bottom-warp-item" @click="goToSlider(2)">
            <div class="warp-item_banvdig" style="display: none;opacity: 1;">
              <img src="https://oss.hzwohu.com/assets/dian.svg" alt="">
            </div>
            <div class="warp-item_banvdte">
              <p class="fm-APT-M">电商运营</p>
            </div>
          </div>
          <div class="bottom-warp-item" @click="goToSlider(3)">
            <div class="warp-item_banvdig" style="display: none;opacity: 1;">
              <img src="https://oss.hzwohu.com/assets/ip.svg" alt="">
            </div>
            <div class="warp-item_banvdte">
              <p class="fm-APT-M">IP孵化</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-wh" id="sectionHome">
      <div class="home-custom wid1800">
        <div class="home-whbg">
          <div class="home-whdiv fm-APT-R">
            <div class="home-whdiv-logo wow fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">
              <img src="https://oss.hzwohu.com/assets/logo.png" alt=""></div>
            <div class="home-whdiv-info wow fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">
              <p style="text-align: center;">杭州沃虎科技有限公司成立于2017年，<br
                  style="margin: 0px; padding: 0px; box-sizing: border-box; white-space: normal; color: rgb(34, 34, 34); font-family: 微软雅黑, &quot;Microsoft Yahei&quot;, &quot;Helvetica Neue&quot;, Helvetica, Tahoma, sans-serif; text-align: center; background-color: rgb(255, 255, 255);">是一家以品质服务、优质内容驱动发展，为客户提供多平台、全品牌整合营销、直播代运营、短视频运营、广告投放等新媒体推广一站式服务的卓越科技公司。
              </p>
            </div>
            <div class="home-whdiv-list" ref="elementToCount">
              <div class="whdiv-list-item wow fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">
                <div class="list-item-a1 list-item-a4" v-if="shouldCountUp">
                  <VueCountUp :start-value="startValue" :end-value="endValue" :duration="1" :options="options" />
                </div>
                <div class="list-item-a2">年公司成立</div>
              </div>

              <div class="whdiv-list-item wow fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">
                <div class="list-item-a1" v-if="shouldCountUp">
                  <VueCountUp :start-value="0" :end-value="200" :duration="1" :options="options" />
                </div>
                <div class="list-item-a2">业务范围</div>
              </div>

              <div class="whdiv-list-item wow fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">
                <div class="list-item-a1" v-if="shouldCountUp">
                  <VueCountUp :start-value="0" :end-value="1000" :duration="1" :options="options" />+
                </div>
                <div class="list-item-a2">累计合作客户</div>
              </div>

              <div class="whdiv-list-item wow fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">
                <div class="list-item-a1" v-if="shouldCountUp">
                  <VueCountUp :start-value="0" :end-value="9" :duration="1" :options="options" />
                </div>
                <div class="list-item-a2">业务遍布区域</div>
              </div>
            </div>
            <div class="partner-more" @click="goAbouts">
              <div @click="lookWohu" class="morebut partner-but cooperate-but fm-APT-L fnt18 wow fadeInUp animated"
                style="visibility: visible; animation-name: fadeInUp;">
                <span>了解沃虎</span>
                <i></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home-news">
      <div class="home-custom wid1800">
        <div class="custom-ac">
          <div class="custom-ab wow fadeInUp animated">
            <div class="custom-a fnt28 gybt-xt fm-APT-R">最新动态</div>
          </div>
        </div>
        <div class="home-news-list  wow fadeInUp animated">
          <div class="news-list-item">
            <div class="item-imgbox"><img src="https://oss.hzwohu.com/assets/news/new01.jpeg" alt="">
            </div>
            <div class="list-item-info">
              <h5>千川三星答辩</h5>
              <p class="list-item-crip">千川三星答辩</p>
              <p class="list-item-time"></p>
            </div>
          </div>
          <div class="news-list-item">
            <div class="item-imgbox"><img src="https://oss.hzwohu.com/assets/news/new02.jpeg" alt="">
            </div>
            <div class="list-item-info">
              <h5>千川三星答辩</h5>
              <p class="list-item-crip">千川三星答辩</p>
              <p class="list-item-time"></p>
            </div>
          </div>
          <div class="news-list-item">
            <div class="item-imgbox"><img src="https://oss.hzwohu.com/assets/news/new03.jpeg" alt="">
            </div>
            <div class="list-item-info">
              <h5>919川游不息</h5>
              <p class="list-item-crip">919川游不息</p>
              <p class="list-item-time"></p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="home-case" id="homecase">
      <div class="home-custom wid1800">
        <div class="custom-ab wow fadeInUp animated">
          <div class="custom-a fnt28 gybt-xt fm-APT-R">客户案例</div>
        </div>
        <div class="case-content">
          <div class="case-content-list">
            <div class="case-content-list-item wow fadeInUp animated" v-for="(item, index) in visibleCases"
              :key="index">
              <el-image style="margin-bottom:10px;width:40vw;height: 350px; box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);"
                :src="item.url" :preview-src-list="[item.url]">
              </el-image>
            </div>
          </div>
        </div>
        <div class="partner-more">
          <div v-if="!showAll" @click="showMore"
            class="morebut partner-but cooperate-but fm-APT-L fnt18 wow fadeInUp animated"
            style="visibility: visible; animation-name: fadeInUp;">
            <span>展开更多</span>
            <i></i>
          </div>
          <a v-else @click="showLess" class="morebut partner-but cooperate-but fm-APT-L fnt18 wow fadeInUp animated"
            style="visibility: visible; animation-name: fadeInUp;">
            <span>收起案例</span>
            <i></i>
          </a>
        </div>
      </div>


    </div>
    <!-- <div class="home-advantage">
      <div class="home-custom wid1800">
        <div class="custom-ab wow fadeInUp animated">
          <div class="custom-a fnt28 gybt-xt fm-APT-R">沃虎优势</div>
        </div>
      </div>
      <div class="home-advantage-content wow fadeInUp animated">
        <div class="advantage-list ">
          <div class="advantage-list-item">
            <div class="advantage-img">
              <img src="https://oss.hzwohu.com/assets/advantage/02.png" alt="">
            </div>
            <div class="advantage-title">
              <img src="https://oss.hzwohu.com/assets/yingxiao.svg" alt="">
              <p>以数据为底层，塑造品牌形象，传递品牌价值。专业团队制定个性化策略，评估策略效果。</p>
              <h3 class="fm-APT-M">数据驱动品牌营销</h3>
            </div>
            <div class="advantage-short-title">
              <h3 class="fm-APT-M">数据驱动品牌营销</h3>
            </div>
          </div>
          <div class="advantage-list-item">
            <div class="advantage-img">
              <img src="https://oss.hzwohu.com/assets/advantage/03.png" alt="">
            </div>
            <div class="advantage-title">
              <img src="https://oss.hzwohu.com/assets/mei.svg" alt="">
              <p>一站式全国媒介流量采买及服务策略。多样化媒介资源覆盖目标受众。</p>
              <h3 class="fm-APT-M">全媒介整合</h3>
            </div>
            <div class="advantage-short-title">
              <h3 class="fm-APT-M">全媒介整合</h3>
            </div>
          </div>
          <div class="advantage-list-item">
            <div class="advantage-img">
              <img src="https://oss.hzwohu.com/assets/advantage/04.png" alt="">
            </div>
            <div class="advantage-title">
              <img src="https://oss.hzwohu.com/assets/dian.svg" alt="">
              <p>全方位电商运营服务，助力店铺增长。自有直播基地、专业主播团队。</p>
              <h3 class="fm-APT-M">强劲电商运营能力</h3>
            </div>
            <div class="advantage-short-title">
              <h3 class="fm-APT-M">强劲电商运营能力</h3>
            </div>
          </div>
          <div class="advantage-list-item">
            <div class="advantage-img">
              <img src="https://oss.hzwohu.com/assets/banner/16859353537129u15pr.png" alt="">
            </div>
            <div class="advantage-title">
              <img src="https://oss.hzwohu.com/assets/ip.svg" alt="">
              <p>完善IP孵化体系，打造行业顶级达人。成功孵化多个高人气IP，提升品牌价值。</p>
              <h3 class="fm-APT-M">强大IP孵化能力</h3>
            </div>
            <div class="advantage-short-title">
              <h3 class="fm-APT-M">强大IP孵化能力</h3>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="bgff">
      <div class="home-custom wid1800">
        <div class="custom-ac">
          <div class="custom-ab wow fadeInUp animated">
            <div class="custom-a fnt28 gybt-xt fm-APT-R">合作伙伴</div>
          </div>
        </div>
        <div class="partner-content">
          <div v-for="(item,index) in visiblePartners" :key="index" class="partner-item wow fadeInUp animated"
            style="visibility: visible; animation-name: fadeInUp;">
            <img class="" :src="item.img">
          </div>
        </div>
        <div class="partner-more" v-if="showMoreButton">
          <div @click="lookMore" class="morebut partner-but cooperate-but fm-APT-L fnt18 wow fadeInUp animated"
            style="visibility: visible; animation-name: fadeInUp;">
            <span>展开更多</span>
            <i></i>
          </div>
        </div>
      </div>
    </div>

    <div class="home-join">
      <div class="home-join">
        <video style="width:100%;height:100%" src="https://oss.hzwohu.com/assets/d68917d2e7f2f3e40ef38874aa94050f.mp4"
          poster="" autoplay="" loop="" muted></video>
        <!-- <video style="width:100%;height:100%" src="https://oss.hzwohu.com/505520a58e5a8276a90662433153b88b.mp4"
          poster="" autoplay="" loop="" muted></video> -->
      </div>
      <div class="home-join_title fm-APT-R">
        <div class="home-join_title-p1">加入沃虎</div>
        <div class="home-join_title-p2">探<span><img src="../assets/sxk.svg" alt="" class="he_huhle he_huhleph"> 沃 <img
              src="../assets/xxk.svg" alt="" class="he_huhri he_huhripc"></span>未来之路，虎跃新高</div>
        <div class="home-join_title-p3">我们始终秉承能岗匹配，知人善用，用人所长的用人机制 给予有能有志且努力的年轻人充分展现自己的舞台</div>
      </div>
    </div>
    <div class="home-join-bottom">更广阔的天地，我们一起去发现</div>
    <div class="home-join-bottom1">////// 加入沃虎</div>
    <Footers></Footers>
  </div>
</template>

<script>
  import $ from 'jquery'; // 引入jQuery库
  import Footers from "@/components/footers";
  import {
    swiper,
    swiperSlide
  } from "vue-awesome-swiper"
  export default {
    components: {
      Footers,
      swiper,
      swiperSlide,
    },
    data() {
      return {
        shouldCountUp: false,
        startValue: 0,
        endValue: 2017,
        options: {
          separator: '',
        },
        partnerList: [{
            img: "https://oss.hzwohu.com/assets/partner/1.jpg",
          },
          {
            img: "https://oss.hzwohu.com/assets/partner/2.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/3.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/4.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/5.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/6.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/7.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/9.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/10.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/11.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/12.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/13.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/14.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/15.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/16.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/17.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/18.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/19.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/20.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/22.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/23.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/24.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/25.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/26.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/27.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/28.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/29.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/30.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/31.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/32.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/34.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/35.jpg"
          }
        ],
        currentIndex: 0,
        scrollDistance: 0,
        isHeaderFixed: false,
        swiperOptionTwo: {
          autoplay: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.course-but-a',
            prevEl: '.course-but-b',
          },
        },
        swiperOption: {
          autoplay: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          on: {
            slideChange: this.slideChangeHandler
          }
        },
        caseList: [{
            url: "https://oss.hzwohu.com/assets/case/case4.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/case2.png",
            type: 'cloth'
          },

          {
            url: "https://oss.hzwohu.com/assets/case/case1.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/case5.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_10.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_01.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_02.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_03.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_04.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_05.png",
            type: 'beauty'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_06.png",
            type: 'beauty'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_07.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_08.png",
            type: 'beauty'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_09.png",
            type: 'cloth'
          },
        ],
        visibleCases: [],
        initialDisplayCount: 2, // 最初显示的案例数量
        increment: 4, // 每次增加的案例数量
        showAll: false, // 是否显示所有案例
        visiblePartners: [],
        showMoreButton: true,
        partnersPerLoad: 16,
      };
    },
    created() {
      this.loadPartners();
    },
    mounted() {
      // 初始化可见案例
      this.visibleCases = this.caseList.slice(0, this.initialDisplayCount);

      window.addEventListener('scroll', this.handleScrolls);
      const $headerNavLi2 = $('.header-nav-join');
      const $headerB = $headerNavLi2.find('.header-b');
      // 使用jQuery绑定事件
      $headerNavLi2.hover(
        function () {
          $headerB.stop().slideDown();
        },
        function () {
          $headerB.stop().slideUp();
        }
      );
      const $bottomItem = $('.bottom-warp-item');

      $bottomItem.each(function () {
        const $thisBottomItem = $(this);
        const $banvdig = $thisBottomItem.find('.warp-item_banvdig');

        $thisBottomItem.hover(
          function () {
            $banvdig.stop().slideDown();
          },
          function () {
            $banvdig.stop().slideUp();
          }
        );
      });
      new this.$wow.WOW().init({ //新建实列
        boxClass: 'wow', //class名字
        animateClass: 'animate__animated', //animateclass动画库类名,和当前animate动画库决定
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true //对异步加载的内容进行操作（默认为true）
      });
      window.addEventListener('scroll', this.handleScroll);
    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('scroll', this.handleScrolls);
    },
    methods: {
      handleAnchorClick(anchor) {
        this.$el.querySelector(anchor).scrollIntoView({
          behavior: 'smooth'
        });
      },
      loadPartners() {
        this.visiblePartners = this.partnerList.slice(0, this.partnersPerLoad);
        this.currentIndex = this.partnersPerLoad;
        if (this.currentIndex >= this.partnerList.length) {
          this.showMoreButton = false;
        }
      },
      lookMore() {
        const nextPartners = this.partnerList.slice(this.currentIndex, this.currentIndex + this.partnersPerLoad);
        this.visiblePartners = this.visiblePartners.concat(nextPartners);
        this.currentIndex += this.partnersPerLoad;
        if (this.currentIndex >= this.partnerList.length) {
          this.showMoreButton = false;
        }
      },
      // 查看更多案例
      showMore() {
        // 显示更多案例
        const endIndex = Math.min(this.visibleCases.length + this.increment, this.caseList.length);
        this.visibleCases = this.caseList.slice(0, endIndex);
        // 如果显示所有案例，则显示收起按钮，否则显示更多按钮
        this.showAll = endIndex === this.caseList.length;
      },
      showLess() {
        // 显示最初的案例数量
        this.visibleCases = this.caseList.slice(0, this.initialDisplayCount);
        // 显示更多按钮，隐藏收起按钮
        this.showAll = false;
        this.$el.querySelector('#homecase').scrollIntoView({
          behavior: 'smooth'
        });
      },
      handleScrolls() {
        const elementToCount = this.$refs.elementToCount;
        const rect = elementToCount.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        if (rect.top <= windowHeight) {
          this.shouldCountUp = true;
        }
      },
      slideChangeHandler() {},
      goAbout() {
        this.$router.push("/about");
        window.scrollTo(0, 0);
      },
      goAbouts() {
        this.$router.push("/about");
        window.scrollTo(0, 0);
      },
      goJon() {
        this.$router.push("/join");
        window.scrollTo(0, 0);
      },
      goCase() {
        this.$router.push("/case");
        window.scrollTo(0, 0);
      },
      lookWohu() {
        this.$router.push("/about");
        window.scrollTo(0, 0);
      },
      goBusiness() {
        this.$router.push("/business");
        window.scrollTo(0, 0);
      },
      handleAnchorClick(anchor) {
        this.$el.querySelector(anchor).scrollIntoView({
          behavior: 'smooth'
        });
      },
      handleScroll() {
        this.scrollDistance = window.scrollY || document.documentElement.scrollTop;
        if (this.scrollDistance > 10) {
          this.isHeaderFixed = true;
        } else {
          this.isHeaderFixed = false;
        }
      },
      goToSlider(val) {
        this.$router.push({
          path: '/business',
          query: {
            slideIndex: val
          }
        });
        window.scrollTo(0, 0);
      }
    },

  };
</script>
<style scoped>
  .home-banner-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
  }

  .banner-bottom-warp {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 5%;
    margin: 0 auto;
  }

  .bottom-warp-item {
    position: relative;
    cursor: pointer;
    width: 20% !important;
    padding: 0 8px;
  }

  .bottom-warp-item:hover {
    background: rgb(198 228 232 / 40%);
  }

  .warp-item_banvdte {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    text-align: center;
    position: relative;
    transition: all 0.86s;
  }

  .warp-item_banvdte p {
    line-height: 54px;
    height: 54px;
    color: #fff;
    font-size: 16px;
  }

  .warp-item_banvdig {
    position: absolute;
    bottom: 57px;
    left: 50%;
    transform: translate(-50%);
  }

  .warp-item_banvdig img {
    width: 100%;
    height: 4vw;
    /* height: 40px; */
  }


  .swiper-button-next {
    background-image: url('https://oss.hzwohu.com/assets/right.png') !important;
  }

  .swiper-button-prev {
    background-image: url('https://oss.hzwohu.com/assets/left.png') !important;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 1 !important;
  }

  .home-slide-common {
    width: 100%;
    height: 100vh;
  }

  .home-slide1 {
    background: url('https://oss.hzwohu.com/assets/home1.jpg') no-repeat;
    /* background: url('https://oss.hzwohu.com/assets/banner/168595108252674w4jm.png') no-repeat; */
    /* background: url('https://oss.hzwohu.com/assets/banner/home_bg.png') no-repeat; */
    background-size: cover;
  }

  .home-slide2 {
    background: url('https://oss.hzwohu.com/assets/banner/168595142041468obq9.png') no-repeat;
  }

  .home-slide-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .home-slide-text p {
    font-size: 1.6vw;
    color: #fff;
    font-weight: 700;
    opacity: 1;
    letter-spacing: 1px;
  }

  .swiper-slide.swiper-slide-active .home-slide-text p {
    top: 0;
    opacity: 1;
    transition: all .7s .5s;
    -webkit-transition: all .7s .5s;
  }

  .header-k {
    width: 52vw;
    left: initial;
    right: 3.125vw;
    background: #fff;
    padding: 2vw 2.3vw;
    overflow: hidden;
  }

  .header-k2 {
    float: left;
    line-height: 1.1;
    padding-bottom: 1.2vw;
    position: relative;
    color: #222;
  }

  .header-b {
    width: 93.75%;
    border-radius: 10px;
    position: absolute;
    top: 100%;
    overflow: hidden;
    display: none;
  }

  .jianli {
    width: 45%;
    display: none;
    height: 112px;
    padding-top: 28.8px;
    margin-top: 0px;
    padding-bottom: 28.8px;
    margin-bottom: 0px;
    box-shadow: 0 5px 10px #ccc;
    font-weight: normal !important;
  }

  .fnt36 {
    font-size: 1.875vw;
  }

  .header-k3 {
    float: right;
    /* text-align: right; */
    line-height: 1.2;
    color: #222;
  }

  .header-k3 a {
    display: inline-block;
    margin-top: 15px;
  }

  .fm-APT-R {
    font-family: Alibaba-PuHuiTi-Regular;
  }

  .fnt30 {
    font-size: 1.5625vw;
  }

  .bj {
    background: #fff;
    border-bottom: 1px solid #ccc;
  }

  header.bj .header-nav {
    color: #222;
    line-height: 5vw;
  }

  header {
    position: fixed;
    z-index: 39;
    width: 100%;
    top: 0;
    left: 0;
  }

  .header {
    transition: all .3s;
    -webkit-transition: all .3s;
  }

  .wid1800 {
    width: 93.75%;
    margin: 0 auto;
  }

  .logo {
    font-size: 0;
    float: left;
    transition: all .3s;
    -webkit-transition: all .3s;
  }

  .logo img {
    width: 10vw;
  }

  .header-nav {
    float: right;
    font-size: 0;
    color: #fff;

  }

  .header-nav-li {
    display: inline-block;
    vertical-align: top;
    vertical-align: top;
    font-size: 0.94vw;
    font-weight: 700;
    line-height: 5vw;
    padding: 0 1.5vw;
    cursor: pointer;
    transition: all .5s;
    -webkit-transition: all .5s;
  }

  .header-nav-li:hover,
  .header-nav-li.on {
    opacity: 1 !important;
    color: #00AAc3 !important;
    transition: all .5s;
    -webkit-transition: all .5s;
  }

  .about-content {
    width: 100%;
    height: 100vh;
    min-height: 45vw;
    position: relative;
    overflow: hidden;
  }

  .home-top {
    width: 100%;
    height: 100vh;
    min-height: 45vw;
    position: relative;
    overflow: hidden;
  }

  .home-custom {
    padding: 4vw 0;
  }

  .custom-ac {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .gybt-xt {
    display: inline-block;
    line-height: 1;
    padding-bottom: .5vw;
    border-bottom: 2px solid #00AAc3;
  }

  .custom-ab {
    display: inline-block;
  }

  .custom-a {
    line-height: 1;
  }

  .fnt28 {
    font-size: 1.4583vw;
  }

  .fnt20 {
    font-size: 1.04166vw;
  }

  .course-but {
    display: flex;
    align-items: center;
  }

  .course-but-a {
    /* display: inline-block; */
    vertical-align: top;
    width: 3.072vw;
    height: 3.072vw;
    background: url('https://oss.hzwohu.com/assets/left1.png') no-repeat center;
    background-size: 100%;
    cursor: pointer;
    margin-right: 10px;

  }

  .course .course-but-a {
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }

  .course-but-a:hover {
    /* background: url('https://oss.hzwohu.com/assets/left-a.png') no-repeat center; */
    background-size: 100%;
  }

  .course-but-b {
    display: inline-block;
    vertical-align: top;
    width: 3.072vw;
    height: 3.072vw;
    margin-top: .2vw;
    background: url('https://oss.hzwohu.com/assets/right2.png') no-repeat center;
    background-size: 100%;
    cursor: pointer;
  }

  .course-but-b:hover {
    /* background: url('https://oss.hzwohu.com/assets/right2-a.png') no-repeat center; */
    background-size: 100%;
  }

  .course .course-but-b {
    transform: rotate(90deg);
  }

  .course-but-b:hover {}

  .partner-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2.2vw;
    width: 100%;
    font-size: 0;
  }

  .partner-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    height: 9vw;
    border-radius: 100%;
    overflow: hidden;
    text-align: center;
    margin-right: 1.65%;
    margin-bottom: 1.65%;
    vertical-align: top;
    background: #fff;
  }

  .partner-item img {
    min-height: 15%;
    min-width: 15%;
    max-width: 75%;
    max-height: 75%;
  }

  .home-news {
    width: 100%;
    background: #f1fbfc;
    /* background: url('http://www.yinlimedia.com/web/upload/2023/08/14/1691997567894a1pc4.png') no-repeat; */
    /* background-size: 100% 100%; */
  }

  .home-news-list {
    display: flex;
    flex-wrap: wrap;
    /* margin-right: -15px;
    margin-left: -15px; */
    margin-top: 2.2vw;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
  }

  .news-list-item {
    width: 33.333333%;
    padding: 0 12px;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .item-imgbox {
    height: 288px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
  }

  .item-imgbox img {
    display: block;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 100%;
    height: 100%;
    transition: all .3s;
    object-fit: cover;
  }

  .list-item-info {
    padding: 32px 24px;
    background-color: #fff;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    box-shadow: 0 0 8px 8px rgba(147, 154, 248, .06);
  }

  .list-item-info h5 {
    font-size: 18px;
    color: #262626;
    margin-bottom: 10px;
    font-weight: 700;
    transition: color .3s;
  }

  .list-item-crip {
    font-size: 14px;
    color: #5e5e5e;
    line-height: 24px;
    text-align: justify;
    height: 72px;
    overflow: hidden;
  }

  .list-item-time {
    font-size: 12px;
    color: grey;
    margin-top: 10px;
  }

  .news-list-item:hover .item-imgbox img {
    transform: scale(1.05)
  }

  .news-list-item:hover .list-item-info h5 {
    color: #00AAc3;
  }

  .home-whbg {
    max-width: 910px;
    background: url('https://oss.hzwohu.com/assets/banner/img17.jpeg') no-repeat;
    background-size: 100% 100%;
    margin: auto;
  }

  .partner-more {
    display: flex;
    justify-content: center;
    margin-top: 4vw;
  }

  .morebut {
    display: inline-block;
    width: 9.375vw;
    line-height: 3.125vw;
    background: #fff;
    border-radius: 1.6vw;
    position: relative;
    color: #222;
    padding: 0 1.8vw;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    transition: all .5s;
    -webkit-transition: all .5s;
  }

  .morebut>span {
    position: relative;
    z-index: 2;
  }

  .morebut>i {
    width: .4vw;
    height: .4vw;
    background: #00AAc3;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    right: 1.8vw;
    z-index: 1;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transition: all .7s;
    -webkit-transition: all .7s;
  }

  .morebut:hover {
    color: #fff;
    font-weight: bold;
    transition: all .5s;
    -webkit-transition: all .5s;
  }

  .morebut:hover>i {
    width: 16vw;
    height: 16vw;
    right: -2.3vw;
    transition: all .7s;
    -webkit-transition: all .7s;
  }

  .partner-but {
    border: 1px solid #ccc;
  }

  .home-whdiv-logo {
    text-align: center;
  }

  .home-whdiv-logo img {
    width: 175px;
  }

  .home-whdiv-list {
    display: flex;
    margin-top: 90px;
    text-align: center;
  }

  .whdiv-list-item {
    width: 25%;
    padding: 0 10px;
  }

  .list-item-a1 {
    font-size: 3.54166vw;
    line-height: 1;
    color: #00AAc3;
    font-weight: 700;
  }

  .list-item-a2 {
    font-size: 14px;
    color: #222;
    line-height: 20px;
    margin-top: 5px;
  }

  .home-whdiv-info {
    margin-top: 30px;
    line-height: 30px;
  }

  .home-whdiv-info p {
    font-size: 16px;
  }

  .home-join {
    width: 100%;
    position: relative;
    height: 40vh;
    background: #f3fafb;
  }

  .home-join video {
    height: 40vh !important;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .home-join_title {
    position: absolute;
    bottom: 20%;
    left: 10%;
    text-align: left;
    color: #fff;
  }

  .home-join_title-p1 {
    font-size: 16px;
    line-height: 1.75;
    color: #fff;
  }

  .home-join_title-p2 {
    margin-top: 17px;
    font-size: 32px;
  }

  .home-join_title-p2 span {
    padding: 0 20px;
    position: relative;
  }

  .he_huhle {
    width: 15px;
    position: absolute;
    top: -4px;
    left: 2px;
    background: transparent;
    vertical-align: middle;
  }

  .he_huhri {
    width: 15px;
    position: absolute;
    bottom: -8px;
    right: 17px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    /* IE 9 */
    -moz-transform: rotate(180deg);
    /* Firefox */
    -webkit-transform: rotate(180deg);
    /* Safari 和 Chrome */
    -o-transform: rotate(180deg);
  }

  .home-join_title-p3 {
    margin-top: 20px;
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
  }

  .home-join-bottom {
    text-align: center;
    margin: 60px auto 40px auto;
    font-size: 2vw;
    line-height: 1.75;
    color: #333333;
  }

  .home-join-bottom1 {
    font-size: 1vw;
    color: #00AAc3;
    margin-bottom: 40px;
    text-align: center;
  }

  .home-advantage-content {
    height: 200px;
    /* background: #1A1A1A; */
    background: url('https://oss.hzwohu.com/assets/banner/you_bg.png');
    background-size: 100% 100%;
    margin-top: 15vw;
    padding: 0 4vw 4vw 4vw;
  }

  .advantage-list {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
  }

  .advantage-list-item {
    position: relative;
    margin-top: -200px;
    width: 20%;
    color: #fff;
    height: 350px;
    border-radius: 10px;
    cursor: pointer;
  }

  .advantage-img img {
    width: 100%;
    height: 350px;
    vertical-align: middle;
    object-fit: cover;
  }

  .advantage-title {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    background: rgb(198 228 232 / 20%);
    height: 350px;
    text-align: center;
  }

  .advantage-title img {
    width: 80px;
    max-height: 100%;
    margin: 20% auto 15%;
    display: block;
    object-fit: cover;
  }

  .advantage-title p {
    margin: 5px 15px 12%;
    line-height: 24px;
    letter-spacing: 1px;
    font-size: 13px;
    min-height: 100px;
  }

  .advantage-title h3 {
    font-size: 20px;
    font-weight: normal;
  }

  .advantage-short-title {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 1;
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.55);
    line-height: 70px;
    opacity: 1;
    height: 70px;
  }

  .advantage-short-title h3 {
    font-size: 20px;
    font-weight: normal;
  }

  .advantage-list-item:hover .advantage-short-title {
    transition: all .2s .1s;
    opacity: 0;
  }

  .advantage-list-item:hover .advantage-title {
    opacity: 1;
    transition: all .2s .1s;
  }

  .home-case {
    background: #fff;
    /* background: url('https://oss.hzwohu.com/assets/banner/you_bg.png') no-repeat; */
  }

  .case-content {
    margin-top: 2.2vw;
  }

  .case-content-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .case-content-list-item img {
    height: 360px;
    margin-bottom: 10px !important;
    box-shadow: 0 0 8px 8px rgba(147, 154, 248, .06);
    cursor: pointer;
  }

  .bgff {
    background: #f3fafb !important;
  }

  .about-d {
    width: 3.7vw;
    position: relative;
    cursor: pointer;
  }

  .about-d img {
    position: relative;
    width: 3.7vw;
    animation: divfirst 2s linear 0s infinite;
    -moz-animation: divfirst 2s linear 0s infinite;
    -webkit-animation: divfirst 2s linear 0s infinite;
    -o-animation: divfirst 2s linear 0s infinite;

  }

  @keyframes divfirst {
    0% {
      bottom: 10px;
    }

    50% {
      bottom: 25px;
    }

    100% {
      bottom: 10px;
    }
  }
</style>