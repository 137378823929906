<template>
  <div>
    <div class="footer hidden-xs">
      <div class="container1 box-flex">
        <div class="footer_one" @click="goPages('business')">
          <ul>
            <li>
              <h5>业务</h5>
            </li>
            <li>
              <p>品牌营销</p>
            </li>
            <li>
              <p>媒介资源</p>
            </li>
            <li>
              <p>电商运营 </p>
            </li>
            <li>
              <p>IP孵化</p>
            </li>
          </ul>
        </div>
        <div class="footer_one" @click="goPages('about')">
          <ul>
            <li>
              <h5>关于我们</h5>
            </li>
            <li>
              <p>了解沃虎</p>
            </li>
            <li>
              <p>平台文化</p>
            </li>
            <li>
              <p>荣誉资质</p>
            </li>
          </ul>
        </div>
        <div class="footer_one" @click="goPages('join')">
          <ul>
            <li>
              <h5>联系我们</h5>
            </li>
            <li class="fot_img">
              <p>
                <img src="https://oss.hzwohu.com/assets/fot1.png" width="100%">
                杭州沃虎科技有限公司
              </p>
            </li>
            <li class="fot_img">
              <p>
                <img src="https://oss.hzwohu.com/assets/fot2.png" width="100%">
                浙江省杭州市滨江区春风大楼8幢17楼
              </p>
            </li>
            <li class="fot_img">
              <p>
                <img src="https://oss.hzwohu.com/assets/fot3.png" width="100%">
                17681845470
              </p>
            </li>
          </ul>

        </div>
        <div class="footer_one">
          <ul>
            <li>
              <h5>扫描二维码关注沃虎</h5>
            </li>
            <li class="foot-qrcode">
              <img src="https://oss.hzwohu.com/assets/qrcode.png" alt="二维码">
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer2">
      <a href="https://beian.miit.gov.cn/" target="_blank" style="color:#f0f0f0;">浙ICP备2023048040号-1</a>
      <p>©2024杭州沃虎科技有限公司版权所有 </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Footers',
    props: {},
    methods: {
      goPages(val) {
        this.$router.push(`/${val}`);
        window.scrollTo(0, 0);
      }
    }
  }
</script>

<style scoped>
  .footer {
    background: #02101e;
    padding: 60px 0;
  }

  @media (min-width: 1200px) {
    .container {
      width: 1024px;
    }

    .container1 {
      width: 1024px;
    }
  }

  @media (min-width: 1400px) {
    .container1 {
      width: 1180px;
    }
  }

  .box-flex {
    display: flex;
    text-align: left;
    justify-content: space-around;
  }

  .footer_one {
    width: 20%;
    position: relative;
    cursor: pointer;
  }

  .container1 {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  ol,
  ul {
    list-style: none
  }

  .footer_one ul li {
    letter-spacing: 1px;
    font-size: 14px;
    color: #666;
  }

  .footer_one h5 {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 0px;
  }

  .footer_one p {
    color: #a6a6a6;
    margin-bottom: 10px;
  }

  .fot_img img {
    width: 17px;
    display: inline-block;
    vertical-align: middle;
  }

  .foot-qrcode img {
    width: 150px;
    border-radius: 10px;
  }

  .footer2 {
    background: #010c16;
    color: #2c353d;
    padding: 25px 0;
    text-align: center;
  }

  .footer2 p {
    color: #a6a6a6;
    margin-bottom: 0px;
  }

  .footer2 a {
    text-decoration: none;
    color: #666;
    background: none;
  }
</style>