<template>
  <div>
    <header ref="headers" :class="{ 'bj': isHeaderFixed }">
      <div class="header wid1800">
        <div class="logo">
          <img src="https://oss.hzwohu.com/assets/logo.png" alt="logo">
        </div>
        <div class="header-nav">
          <div class="header-nav-li" @click="goIndex">首页</div>
          <div class="header-nav-li on">关于沃虎</div>
          <div class="header-nav-li" @click="goBusiness">公司业务</div>
          <div class="header-nav-li" @click="goJon">联系我们</div>
          <div class="header-nav-li header-nav-join">
            加入我们
            <div class="header-b header-k jianli">
              <div class="header-k2 fnt36 fm-APT-R">
                简历投递请联系
              </div>
              <div class="header-k3 fm-APT-R" style="font-size:15px;">
                HRBP 涂女士：18106511396（手机和微信同号）<br>
                <a href="https://www.zhipin.com/gongsi/job/0abc679d53ebe2151nV72dW8EA~~.html?ka=all-jobs-hot"
                  target="_blank">人才通道</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="about-content">

      <div>
        <!-- <video class="banner-video" ref="video" autoplay="" loop="" muted @canplaythrough="playVideoForTwoSeconds"
          poster="https://oss.hzwohu.com/assets/banner/homepage.jpeg">
          <source src="https://www.bluefocus.com/skin/video/about.mp4" type="video/mp4">
        </video> -->
        <!-- <video class="banner-video" ref="video" autoplay="" loop="" muted
          poster="https://oss.hzwohu.com/assets/banner/homepage.jpeg">
          <source src="https://oss.hzwohu.com/assets/3c5648b77056433952fd97837b8ff86a.mp4" type="video/mp4">
        </video> -->
         <video class="banner-video" ref="video" autoplay="" loop="" muted
          poster="https://oss.hzwohu.com/assets/banner/homepage.jpeg">
          <source src="https://oss.hzwohu.com/assets/abouts.mp4" type="video/mp4">
        </video>
      </div>
      <div class="about-head-content">
        <p class="fm-APT-M p2 wow fadeIn" data-wow-duration="1s" data-wow-delay="1s"
          style="visibility: visible; animation-duration: 1s; animation-delay: 1s; animation-name: fadeIn;">沃虎科技</p>
        <p class="fm-APT-M p1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="2s"
          style="visibility: visible; animation-duration: 1s; animation-delay: 2s; animation-name: fadeInUp;">
          致力成为新媒体广告行业服务标杆，为全体优秀员工的美好生活而奋进</p>
      </div>
      <!-- <div class="join-lt about-lt fnt50 fm-APT-R">
        <div class="join-lt-b wow fadeInLeft animated wow slideInLeft" data-wow-delay=".5s">沃虎科技</div>
      </div> -->
      <div class="about-ups animated wow fadeInDown" data-wow-delay=".7s">
        <a @click="handleAnchorClick('#section1')">
          <div class="about-d"><img src="https://oss.hzwohu.com/assets/banner/ico16.png"></div>
        </a>
      </div>
      <div class="about-div wow fadeInRight animated wow fadeInRight" data-wow-delay=".7s">
        <!-- <div class="about-c fnt28 fm-APT-R">
          <p><span style="font-size: 14px;">致力成为新媒体广告行业服务标杆，为全体优秀员工的美好生活而奋进</span></p>
        </div> -->
        <!-- <a v-on:click="handleAnchorClick('#section1')">
          <div class="about-d"><img src="https://oss.hzwohu.com/assets/banner/ico16.png"></div>
        </a> -->
      </div>
    </div>
    <div>


      <div class="about-wh wid1800" id="section1">
        <div class="course-ab wow fadeInLeft animated">
          <div class="course-a fnt36 gybt-xt fm-APT-R">公司简介</div>
        </div>
        <div class="fm-APT-R intro-right">
          <p class="chn wow fadeInUp" data-wow-duration="1s" data-wow-delay="0"
            style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">
            杭州沃虎科技有限公司（下称“本公司”）成立于2017年，目前主要业务范畴为新媒体广告业务、直播体系新零售、IP孵化等。本公司目前已成为一家在抖音广告和电商待遇应服务领域的TOP级机构，并荣获杭州市“瞪羚企业”“国家高新技术企业”“滨江区服务业龙头”“富阳区突出贡献企业”等称号。公司拥有完整的服务团队，涵盖运营、设计、视频制作、数据分析、直播搭建、售后承接等板块，从营销的各个环节为企业量身定制广告创意及推广方案。已帮助多个品牌实现0-1的成长，沉淀品牌价值，合作品牌客户超千家！
          </p>
        </div>
      </div>
    </div>
    <div class="about-history">
      <div class="wid1800 company-intro-page">
        <div class="course-ab wow fadeInLeft animated">
          <div class="course-a fnt36 gybt-xt fm-APT-R" style="color:#fff;">发展历程</div>
        </div>
        <div id="development" class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"
          style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">
          <div class="dev-list-wrap">
            <div class="dev-list-item">
              <div class="time">
                <div class="year">2024</div>
                <div class="desc">展望</div>
              </div>
              <div class="icon"></div>
              <div class="content-list">
                <div class="content-item">
                  <div class="row">
                    <div class="content">
                      2月再次获得杭州市滨江区“服务业龙头”称号
                    </div>
                    <div class="content">3月在全体同仁努力下，斩获巨量引擎共擎奖“营销领军奖”、“千川创新奖”“直播巨擎奖”以及杭州市富阳区“突出贡献企业”称号</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dev-list-item">
              <div class="time">
                <div class="year">2023</div>
                <div class="desc">质变</div>
              </div>
              <div class="icon"></div>
              <div class="content-list">
                <div class="content-item">
                  <div class="row">
                    <div class="content">5月荣获巨量引擎共擎奖“年度整合营销案例奖”以及“年度服务突破奖</div>
                    <div class="content">8月荣升千川“三星级”服务商</div>
                    <div class="content">全体同仁展望未来，期待再攀高峰！</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dev-list-item">
              <div class="time">
                <div class="year">2022</div>
                <div class="desc">进阶</div>
              </div>
              <div class="icon"></div>
              <div class="content-list">
                <div class="content-item">
                  <div class="row">
                    <div class="content">荣升千川“二星级”服务商并获得巨量引擎全类目广告服务资质</div>
                    <div class="content">同时获得杭州滨江区“服务业龙头”称号</div>
                    <div class="content">同年开启抖音达人孵化及变现赛道</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dev-list-item">
              <div class="time">
                <div class="year">2021</div>
                <div class="desc">攀登</div>
              </div>
              <div class="icon"></div>
              <div class="content-list">
                <div class="content-item">
                  <div class="row">
                    <div class="content">成为首批千川服务商</div>
                    <div class="content">帮助多家品牌开启抖音电商新征程</div>
                    <div class="content">并荣获“国家高新技术企业”称号</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dev-list-item">
              <div class="time">
                <div class="year">2020</div>
                <div class="desc">崭露</div>
              </div>
              <div class="icon"></div>
              <div class="content-list">
                <div class="content-item">
                  <div class="row">
                    <div class="content">进入广告代理行业</div>
                    <div class="content">是最早一批以直播运营服务为主的新媒体广告公司</div>
                    <div class="content">同年获得杭州市“瞪羚企业”称号</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dev-list-item">
              <div class="time">
                <div class="year">2019</div>
                <div class="desc">跨界</div>
              </div>
              <div class="icon"></div>
              <div class="content-list">
                <div class="content-item">
                  <div class="row">
                    <div class="content">组建信息流团队</div>
                    <div class="content">切入鲁班电商，正式进入电商行业</div>
                    <div class="content">开启了抖音之旅</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dev-list-item">
              <div class="time">
                <div class="year">2018</div>
                <div class="desc">突破</div>
              </div>
              <div class="icon"></div>
              <div class="content-list">
                <div class="content-item">
                  <div class="row">
                    <div class="content">成功研发小沃智能音响</div>
                    <div class="content">并获得该年度滨江区大学生创业创新一等奖</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dev-list-item">
              <div class="time">
                <div class="year">2017</div>
                <div class="desc">成立</div>
              </div>
              <div class="icon"></div>
              <div class="content-list">
                <div class="content-item">
                  <div class="row">
                    <div class="content">杭州科技有限公司在杭州注册成立</div>
                    <div class="content">主营：线上销售智能家具产品</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-culture">
      <div class="culture wid1800">
        <div class="culture-tit fnt36 fm-APT-R wow fadeInUp animated"
          style="visibility: visible; animation-name: fadeInUp;">
          <div class="gybt-xt">企业文化</div>
        </div>
        <div class="culture-content fm-APT-L">
          <div class="culture-li wow fadeInRight animated" data-wow-delay=".2s"
            style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInRight;">
            <img src="https://oss.hzwohu.com/assets/banner/ico19.png">
            <div class="culture-div fm-APT-L">
              <div class="culture-a">
                <img class="img1" src="https://oss.hzwohu.com/assets/icon/mission.png">
                <img class="img2" src="https://oss.hzwohu.com/assets/icon/mission-a.png">
              </div>
              <div class="culture-b fnt36">
                使命</div>
              <div class="culture-c fnt20">
                <p><span style="font-size: 14px;">陪伴客户成为</span></p>
                <p><span style="font-size: 14px;">兴趣电商行业的领导者</span></p>
                <p><br></p>
              </div>
            </div>
          </div>
          <div class="culture-li wow fadeInRight animated" data-wow-delay=".5s"
            style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInRight;">
            <img src="https://oss.hzwohu.com/assets/banner/ico19.png">
            <div class="culture-div fm-APT-L">
              <div class="culture-a">
                <img class="img1" src="https://oss.hzwohu.com/assets/icon/vision.png">
                <img class="img2" src="https://oss.hzwohu.com/assets/icon/vision-a.png">
              </div>
              <div class="culture-b fnt36">
                愿景 </div>
              <div class="culture-c fnt20">
                <p><span style="font-size: 14px;">成为新媒体广告行业服务标杆，</span></p>
                <p><span style="font-size: 14px;">为全体优秀员工的美好生活而奋进</span></p>
                <p><br></p>
              </div>
            </div>
          </div>
          <div class="culture-li wow fadeInRight animated" data-wow-delay=".8s"
            style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInRight;">
            <img src="https://oss.hzwohu.com/assets/banner/ico19.png">
            <div class="culture-div fm-APT-L">
              <div class="culture-a">
                <img style="height:auto;" class="img1" src="https://oss.hzwohu.com/assets/icon/values.png">
                <img style="height:auto;" class="img2" src="https://oss.hzwohu.com/assets/icon/values-a.png">
              </div>
              <div class="culture-b fnt36">
                价值观 </div>
              <div class="culture-c fnt20">
                <p><span style="font-size: 14px;">坦诚、精进、分享、感恩</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-honorary">
      <div class="honorary wid1800">
        <div class="fnt36 fm-APT-R wow fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">
          <div class="gybt-xt">荣誉资质</div>
        </div>
        <div class="honorary-content wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"
          style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">
          <div class="honorary-swiper">
            <el-carousel :autoplay="false" indicator-position="outside" arrow="always">
              <el-carousel-item>
                <div class="carouses-content">
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/5.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/5.png']" />
                      </div>
                      <p class="contents-p" title="营销科学服务商">营销科学服务商</p>
                    </div>
                  </div>
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/6.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/6.png']" />
                      </div>
                      <p class="contents-p" title="营销科学服务商">营销科学服务商</p>
                    </div>
                  </div>
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/7.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/7.png']" />
                      </div>
                      <p class="contents-p" title="国家高新技术企业">国家高新技术企业</p>
                    </div>
                  </div>
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/8.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/8.png']" />
                      </div>
                      <p class="contents-p" title="2022年度服务业龙头企业">2022年度服务业龙头企业</p>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="carouses-content">
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/9.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/9.png']" />
                      </div>
                      <p class="contents-p" title="闭环电商代理商">闭环电商代理商</p>
                    </div>
                  </div>
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/10.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/10.png']" />
                      </div>
                      <p class="contents-p" title="杭州市“瞪羚企业”">杭州市“瞪羚企业”</p>
                    </div>
                  </div>
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/11.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/11.png']" />
                      </div>
                      <p class="contents-p" title="巨量引擎共擎奖">巨量引擎共擎奖</p>
                    </div>
                  </div>
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/12.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/12.png']" />
                      </div>
                      <p class="contents-p" title="巨量引擎共擎奖">巨量引擎共擎奖</p>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="carouses-content">
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/1.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/1.png']" />
                      </div>
                      <p class="contents-p" title="巨量引擎综合代理商">巨量引擎综合代理商</p>
                    </div>
                  </div>
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/2.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/2.png']" />
                      </div>
                      <p class="contents-p" title="巨量本地推综合代理商">巨量本地推综合代理商</p>
                    </div>
                  </div>
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/3.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/3.png']" />
                      </div>
                      <p class="contents-p" title="巨量星图代理商">巨量星图代理商</p>
                    </div>
                  </div>
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/4.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/4.png']" />
                      </div>
                      <p class="contents-p" title="巨量千川服务商">巨量千川服务商</p>
                    </div>
                  </div>
                </div>
              </el-carousel-item>

              <el-carousel-item>
                <div class="carouses-content">
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/13.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/13.png']" />
                      </div>
                      <p class="contents-p" title="直播共擎奖">直播共擎奖</p>
                    </div>
                  </div>
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/14.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/14.png']" />
                      </div>
                      <p class="contents-p" title="巨量引擎生意爆量奖">巨量引擎生意爆量奖</p>
                    </div>
                  </div>
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/15.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/15.png']" />
                      </div>
                      <p class="contents-p" title="千川创新奖">千川创新奖</p>
                    </div>
                  </div>
                  <div class="contents-item">
                    <div style="padding: 15px;">
                      <div class="item-image">
                        <el-image style="width: 100%;object-fit: contain;"
                          src="https://oss.hzwohu.com/assets/honor/16.png"
                          :preview-src-list="['https://oss.hzwohu.com/assets/honor/16.png']" />
                      </div>
                      <p class="contents-p" title="千川创新奖">千川创新奖</p>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="contents-item">
                  <div style="padding: 15px;">
                    <div class="item-image">
                      <el-image style="width: 100%;object-fit: contain;"
                        src="https://oss.hzwohu.com/assets/honor/17.png"
                        :preview-src-list="['https://oss.hzwohu.com/assets/honor/17.png']" />
                    </div>
                    <p class="contents-p" title="营销领军奖">营销领军奖</p>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="about-partner">
      <div class="partner wid1800">
        <div class="fnt36 fm-APT-R wow fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">
          <div class="gybt-xt">合作伙伴</div>
        </div>
        <div class="partner-content">
          <div v-for="(item,index) in visiblePartners" :key="index" class="partner-item wow fadeInUp animated"
            style="visibility: visible; animation-name: fadeInUp;">
            <img class="" :src="item.img">
          </div>
        </div>
        <div class="partner-more" v-if="showMoreButton">
          <div @click="lookMore" class="morebut partner-but cooperate-but fm-APT-L fnt18 wow fadeInUp animated"
            style="visibility: visible; animation-name: fadeInUp;">
            <span>展开更多</span>
            <i></i>
          </div>
        </div>
      </div>
    </div>
    <Footers></Footers>
  </div>
</template>

<script>
  import $ from 'jquery'; // 引入jQuery库
  import Footers from "@/components/footers";
  export default {
    components: {
      Footers,
    },
    data() {
      return {
        visiblePartners: [],
        showMoreButton: true,
        partnersPerLoad: 16,
        currentIndex: 0,
        scrollDistance: 0,
        isHeaderFixed: false,
        partnerList: [{
            img: "https://oss.hzwohu.com/assets/partner/1.jpg",
          },
          {
            img: "https://oss.hzwohu.com/assets/partner/2.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/3.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/4.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/5.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/6.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/7.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/9.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/10.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/11.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/12.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/13.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/14.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/15.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/16.jpg",
          }, {
            img: "https://oss.hzwohu.com/assets/partner/17.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/18.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/19.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/20.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/22.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/23.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/24.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/25.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/26.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/27.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/28.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/29.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/30.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/31.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/32.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/34.jpg"
          }, {
            img: "https://oss.hzwohu.com/assets/partner/35.jpg"
          }
        ],
      };
    },
    created() {
      this.loadPartners();
    },
    mounted() {
      const $headerNavLi2 = $('.header-nav-join');
      const $headerB = $headerNavLi2.find('.header-b');

      // 使用jQuery绑定事件
      $headerNavLi2.hover(
        function () {
          $headerB.stop().slideDown();
        },
        function () {
          $headerB.stop().slideUp();
        }
      );
      new this.$wow.WOW().init({ //新建实列
        boxClass: 'wow', //class名字
        animateClass: 'animate__animated', //animateclass动画库类名,和当前animate动画库决定
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true //对异步加载的内容进行操作（默认为true）
      });
      window.addEventListener('scroll', this.handleScroll);

    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      loadPartners() {
        this.visiblePartners = this.partnerList.slice(0, this.partnersPerLoad);
        this.currentIndex = this.partnersPerLoad;
        if (this.currentIndex >= this.partnerList.length) {
          this.showMoreButton = false;
        }
      },
      lookMore() {
        const nextPartners = this.partnerList.slice(this.currentIndex, this.currentIndex + this.partnersPerLoad);
        this.visiblePartners = this.visiblePartners.concat(nextPartners);
        this.currentIndex += this.partnersPerLoad;
        if (this.currentIndex >= this.partnerList.length) {
          this.showMoreButton = false;
        }
      },
      goIndex() {
        this.$router.push("/");
        window.scrollTo(0, 0);
      },
      goJon() {
        this.$router.push("/join");
        window.scrollTo(0, 0);
      },
      goBusiness() {
        this.$router.push("/business");
        window.scrollTo(0, 0);
      },
      handleAnchorClick(anchor) {
        this.$el.querySelector(anchor).scrollIntoView({
          behavior: 'smooth'
        });
      },
      handleScroll() {
        this.scrollDistance = window.scrollY || document.documentElement.scrollTop;
        if (this.scrollDistance > 10) {
          this.isHeaderFixed = true;
        } else {
          this.isHeaderFixed = false;
        }
      },
    },

  };
</script>
<style scoped>
::v-deep .el-image__inner {
    height: 290px;
    object-fit: contain;
}
  .header-k {
    width: 52vw;
    left: initial;
    right: 3.125vw;
    background: #fff;
    padding: 2vw 2.3vw;
    overflow: hidden;
  }

  .header-k2 {
    float: left;
    line-height: 1.1;
    padding-bottom: 1.2vw;
    position: relative;
    color: #222;
  }

  .header-b {
    width: 93.75%;
    border-radius: 10px;
    position: absolute;
    top: 100%;
    overflow: hidden;
    display: none;
  }

  .jianli {
    width: 45%;
    display: none;
    height: 112px;
    padding-top: 28.8px;
    margin-top: 0px;
    padding-bottom: 28.8px;
    margin-bottom: 0px;
    box-shadow: 0 5px 10px #ccc;
    font-weight: normal !important;
  }

  .fnt36 {
    font-size: 1.875vw;
  }

  .header-k3 {
    float: right;
    /* text-align: right; */
    line-height: 1.2;
    color: #222;
  }

  .header-k3 a {
    display: inline-block;
    margin-top: 15px;
  }


  .fm-APT-R {
    font-family: Alibaba-PuHuiTi-Regular;
  }

  .fnt30 {
    font-size: 1.5625vw;
  }

  .bj {
    background: #fff;
    border-bottom: 1px solid #ccc;
  }

  header.bj .header-nav {
    color: #222;
    line-height: 5vw;
  }

  header {
    position: fixed;
    z-index: 39;
    width: 100%;
    top: 0;
    left: 0;
  }

  .header {
    transition: all .3s;
    -webkit-transition: all .3s;
  }

  .wid1800 {
    width: 1200px;
    margin: 0 auto;
  }

  .logo {
    font-size: 0;
    float: left;
    transition: all .3s;
    -webkit-transition: all .3s;
  }

  .logo img {
    width: 10vw;
  }

  .header-nav {
    float: right;
    font-size: 0;
    color: #fff;

  }

  .header-nav-li {
    display: inline-block;
    vertical-align: top;
    vertical-align: top;
    font-size: 0.94vw;
    font-weight: 700;
    line-height: 5vw;
    padding: 0 1.5vw;
    /* opacity: .3; */
    cursor: pointer;
    transition: all .5s;
    -webkit-transition: all .5s;
  }

  .header-nav-li:hover,
  .header-nav-li.on {
    opacity: 1 !important;
    color: #00AAc3 !important;
    transition: all .5s;
    -webkit-transition: all .5s;
  }

  .about-content {
    width: 100%;
    height: 100vh;
    min-height: 45vw;
    position: relative;
    overflow: hidden;
  }

  .banner-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url('https://www.bluefocus.com/skin/images/homepage.jpg') no-repeat;
    background-size: cover;
  }

  .about-ups {
    position: absolute;
    bottom: 10%;
    left: 50%;
    margin-left: -1.75vw;
  }

  .about-head-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .about-head-content .p1 {
    color: #fff;
    margin-top: 10px;
    font-size: 17px;
    letter-spacing: 1px;
    font-weight: 700;
  }

  .about-head-content .p2 {
    color: #fff;
    font-size: 25px;
    letter-spacing: 1px;
  }

  .imgBj {
    background: no-repeat center;
    background-size: cover;
  }

  .imgBj.pos {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .about-lt {
    color: #fff;
    position: absolute;
    left: 3.125vw;
    top: 9.3vw;
    z-index: 2;
  }

  .about-ul {
    width: 100%;
    padding: 0 3.125vw;
    position: absolute;
    left: 0;
    bottom: 2.2vw;
    z-index: 2;
    color: #fff;
    overflow: hidden;
  }

  .about-li {
    width: 22.666%;
    float: left;
    text-align: center;
  }

  .fnt50 {
    font-size: 2.6041666vw;
  }

  .join-lt {
    width: 14.5%;
    display: inline-block;
    vertical-align: top;
    padding-right: 8%;
    line-height: 1;
    text-transform: uppercase;
  }

  .join-lt-b {
    margin-top: 1.2vw;
    line-height: 1.2;
  }

  .about-div {
    position: absolute;
    top: 40%;
    right: 3.125vw;
    color: #fff;
  }

  .about-c {
    color: #fff;
  }

  .fm-APT-R {
    font-family: Alibaba-PuHuiTi-Regular;
  }

  .fnt28 {
    text-align: left;
    font-size: 1.4583vw;
  }

  .about-d {
    width: 3.7vw;
    margin-top: 3.7vw;
    position: relative;
    cursor: pointer;
  }

  .about-d {
    width: 3.7vw;
    margin-top: 3.7vw;
    position: relative;
    cursor: pointer;
  }

  .about-d img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: divfirst 2s linear 0s infinite;
    -moz-animation: divfirst 2s linear 0s infinite;
    -webkit-animation: divfirst 2s linear 0s infinite;
    -o-animation: divfirst 2s linear 0s infinite;

  }

  @keyframes divfirst {
    0% {
      top: 0px;
    }

    50% {
      top: 25px;
    }

    100% {
      top: 0px;
    }
  }

  @-moz-keyframes divfirst {
    0% {
      top: 0px;
    }

    50% {
      top: 25px;
    }

    100% {
      top: 0px;
    }
  }

  @-webkit-keyframes divfirst {
    0% {
      top: 0px;
    }

    50% {
      top: 25px;
    }

    100% {
      top: 0px;
    }
  }

  @-o-keyframes divfirst {
    0% {
      top: 0px;
    }

    50% {
      top: 25px;
    }

    100% {
      top: 0px;
    }
  }

  .about-wh {
    width: 100%;
    padding: 0 3.125vw;
    padding-top: 3.75vw;
    padding-bottom: 8vw;
    position: relative;
    overflow: hidden;
  }

  .wid1800 {
    width: 93.75%;
    margin: 0 auto;
  }

  .course-a.gybt-xt {
    margin-bottom: .5vw;
  }

  .gybt-xt {
    display: inline-block;
    line-height: 1;
    padding-bottom: .5vw;
    border-bottom: 2px solid #00AAc3;
  }

  .fnt36 {
    font-size: 1.875vw;
  }

  .intro-right {
    margin-left: 450px;
  }

  p.chn {
    font-size: 16px;
    line-height: 28px;
  }

  .about-history {
    width: 100%;
    padding: 0 3.125vw;
    padding-top: 3.75vw;
    padding-bottom: 8vw;
    position: relative;
    overflow: hidden;
    background: url('https://oss.hzwohu.com/assets/history.png') no-repeat;
    background-size: 100% 100%;
  }

  .dev-list-wrap {
    width: 55%;
    margin: 0 auto;
  }

  .dev-list-item {
    display: flex;
    justify-content: center;
    align-items: stretch;
  }

  .dev-list-item .time {
    width: 207px;
    text-align: right;
  }

  .dev-list-item .time .year {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    line-height: 1.2;
    letter-spacing: 1px;
    text-align: right;
    width: 100%;
  }

  .dev-list-item .time .desc {
    font-size: 18px;
    color: #a7a7a7;
    line-height: 1.2;
    margin-top: 4px;
    text-align: right;
    width: 100%;
  }

  .dev-list-item .icon {
    position: relative;
    width: 16px;
    margin: 0 40px;
    display: flex;
    justify-content: center;
  }

  .dev-list-wrap .dev-list-item .icon:before {
    content: "";
    display: inline-block;
    height: 16px;
    width: 26px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARBAMAAADJQ1rJAAAAMFBMVEUAAACXl5eZmZnHx8eXl5fY2NiXl5eXl5eXl5eYmJjZ2dnY2NiWlpaXl5eYmJjY2NhRa0JNAAAAD3RSTlMAZDUJ4dzSxbCZgMahhXnRQw/FAAAAZ0lEQVQI12MAAsWHCgxgIJJ5M0UAxLAoYmBgqjAAsnpAXJ4GBgbGAualXxczFDIwsChY////20CFgUGNYf3///8XsCkwSDLEA1kBnAIMbxn+A8EH3gcIMYQ6hF6EeQg7EPYi3IJwHwDyOC//BU0XFgAAAABJRU5ErkJggg==) 50% no-repeat, -webkit-gradient(linear, left top, left bottom, from(#2a2827), to(#2a2827));
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARBAMAAADJQ1rJAAAAMFBMVEUAAACXl5eZmZnHx8eXl5fY2NiXl5eXl5eXl5eYmJjZ2dnY2NiWlpaXl5eYmJjY2NhRa0JNAAAAD3RSTlMAZDUJ4dzSxbCZgMahhXnRQw/FAAAAZ0lEQVQI12MAAsWHCgxgIJJ5M0UAxLAoYmBgqjAAsnpAXJ4GBgbGAualXxczFDIwsChY////20CFgUGNYf3///8XsCkwSDLEA1kBnAIMbxn+A8EH3gcIMYQ6hF6EeQg7EPYi3IJwHwDyOC//BU0XFgAAAABJRU5ErkJggg==) 50% no-repeat, -webkit-linear-gradient(#2a2827, #2a2827);
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARBAMAAADJQ1rJAAAAMFBMVEUAAACXl5eZmZnHx8eXl5fY2NiXl5eXl5eXl5eYmJjZ2dnY2NiWlpaXl5eYmJjY2NhRa0JNAAAAD3RSTlMAZDUJ4dzSxbCZgMahhXnRQw/FAAAAZ0lEQVQI12MAAsWHCgxgIJJ5M0UAxLAoYmBgqjAAsnpAXJ4GBgbGAualXxczFDIwsChY////20CFgUGNYf3///8XsCkwSDLEA1kBnAIMbxn+A8EH3gcIMYQ6hF6EeQg7EPYi3IJwHwDyOC//BU0XFgAAAABJRU5ErkJggg==) 50% no-repeat, -o-linear-gradient(#2a2827, #2a2827);
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARBAMAAADJQ1rJAAAAMFBMVEUAAACXl5eZmZnHx8eXl5fY2NiXl5eXl5eXl5eYmJjZ2dnY2NiWlpaXl5eYmJjY2NhRa0JNAAAAD3RSTlMAZDUJ4dzSxbCZgMahhXnRQw/FAAAAZ0lEQVQI12MAAsWHCgxgIJJ5M0UAxLAoYmBgqjAAsnpAXJ4GBgbGAualXxczFDIwsChY////20CFgUGNYf3///8XsCkwSDLEA1kBnAIMbxn+A8EH3gcIMYQ6hF6EeQg7EPYi3IJwHwDyOC//BU0XFgAAAABJRU5ErkJggg==) 50% no-repeat, linear-gradient(#2a2827, #2a2827);
    background-size: contain, cover;
    z-index: 1
  }

  .dev-list-wrap .dev-list-item:first-child .icon:after {
    top: 50%
  }

  .dev-list-wrap .dev-list-item:last-child .icon:after {
    bottom: 50%
  }

  .dev-list-item .icon:after {
    content: "";
    height: 100%;
    width: 1px;
    background: #dedede;
    position: absolute;
  }

  .dev-list-item .content-list {

    flex: 1;
    flex-grow: 1;
    margin-left: auto;
    width: 400px;
    vertical-align: top;
    display: inline-block;
    padding: 12px 0;
  }

  .dev-list-wrap .dev-list-item .content-list .content-item {
    display: inline-block;
    padding: 12px 11px;
    background: rgba(0, 0, 0, .2);
    border-radius: 5px;
    border: 1px solid hsla(0, 0%, 100%, .41);
    box-sizing: border-box;
    height: auto;
    vertical-align: middle;
    margin-right: 10px;
    position: relative
  }

  .dev-list-item .content-list .content-item:before {
    content: "";
    position: absolute;
    left: .013333rem;
    width: .013333rem;
    height: max-content;
    background: #dedede;
  }

  .dev-list-item .content-list .content-item .content {
    margin-top: 5px;
    font-size: 15px;
    color: #cacaca;
    line-height: 1.5;
    display: flex;
    align-items: center;
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .content-list .content-item .content:before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-right: 6px;
    border-radius: 50%;
    background: #cacaca
  }



  .company-intro-page #development .dev-list-wrap .dev-list-item {
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item:first-child .icon:after {
    top: 50%
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item:last-child .icon:after {
    bottom: 50%
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .content-list,
  .company-intro-page #development .dev-list-wrap .dev-list-item .icon,
  .company-intro-page #development .dev-list-wrap .dev-list-item .time {
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .time {
    width: 200px;
    text-align: right
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .time .year {
    font-size: 21px;
    font-weight: 700;
    color: #fff;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: right;
    width: 100%
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .time .desc {
    font-size: 17px;
    color: #a7a7a7;
    line-height: 1.2;
    margin-top: 4px;
    text-align: right;
    width: 100%
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .icon {
    position: relative;
    width: 16px;
    margin: 0 50px;
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .icon:before {
    content: "";
    display: inline-block;
    height: 26px;
    width: 16px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARBAMAAADJQ1rJAAAAMFBMVEUAAACXl5eZmZnHx8eXl5fY2NiXl5eXl5eXl5eYmJjZ2dnY2NiWlpaXl5eYmJjY2NhRa0JNAAAAD3RSTlMAZDUJ4dzSxbCZgMahhXnRQw/FAAAAZ0lEQVQI12MAAsWHCgxgIJJ5M0UAxLAoYmBgqjAAsnpAXJ4GBgbGAualXxczFDIwsChY////20CFgUGNYf3///8XsCkwSDLEA1kBnAIMbxn+A8EH3gcIMYQ6hF6EeQg7EPYi3IJwHwDyOC//BU0XFgAAAABJRU5ErkJggg==) 50% no-repeat, -webkit-gradient(linear, left top, left bottom, from(#2a2827), to(#2a2827));
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARBAMAAADJQ1rJAAAAMFBMVEUAAACXl5eZmZnHx8eXl5fY2NiXl5eXl5eXl5eYmJjZ2dnY2NiWlpaXl5eYmJjY2NhRa0JNAAAAD3RSTlMAZDUJ4dzSxbCZgMahhXnRQw/FAAAAZ0lEQVQI12MAAsWHCgxgIJJ5M0UAxLAoYmBgqjAAsnpAXJ4GBgbGAualXxczFDIwsChY////20CFgUGNYf3///8XsCkwSDLEA1kBnAIMbxn+A8EH3gcIMYQ6hF6EeQg7EPYi3IJwHwDyOC//BU0XFgAAAABJRU5ErkJggg==) 50% no-repeat, -webkit-linear-gradient(#2a2827, #2a2827);
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARBAMAAADJQ1rJAAAAMFBMVEUAAACXl5eZmZnHx8eXl5fY2NiXl5eXl5eXl5eYmJjZ2dnY2NiWlpaXl5eYmJjY2NhRa0JNAAAAD3RSTlMAZDUJ4dzSxbCZgMahhXnRQw/FAAAAZ0lEQVQI12MAAsWHCgxgIJJ5M0UAxLAoYmBgqjAAsnpAXJ4GBgbGAualXxczFDIwsChY////20CFgUGNYf3///8XsCkwSDLEA1kBnAIMbxn+A8EH3gcIMYQ6hF6EeQg7EPYi3IJwHwDyOC//BU0XFgAAAABJRU5ErkJggg==) 50% no-repeat, -o-linear-gradient(#2a2827, #2a2827);
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARBAMAAADJQ1rJAAAAMFBMVEUAAACXl5eZmZnHx8eXl5fY2NiXl5eXl5eXl5eYmJjZ2dnY2NiWlpaXl5eYmJjY2NhRa0JNAAAAD3RSTlMAZDUJ4dzSxbCZgMahhXnRQw/FAAAAZ0lEQVQI12MAAsWHCgxgIJJ5M0UAxLAoYmBgqjAAsnpAXJ4GBgbGAualXxczFDIwsChY////20CFgUGNYf3///8XsCkwSDLEA1kBnAIMbxn+A8EH3gcIMYQ6hF6EeQg7EPYi3IJwHwDyOC//BU0XFgAAAABJRU5ErkJggg==) 50% no-repeat, linear-gradient(#2a2827, #2a2827);
    background-size: contain, cover;
    z-index: 1
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .icon:after {
    content: "";
    height: 100%;
    width: 1px;
    background: #dedede;
    position: absolute
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .icon:first-child:after {
    margin-top: .626667rem
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .content-list {
    flex: 1;
    flex-grow: 1;
    margin-left: auto;
    vertical-align: top;
    display: inline-block;
    padding: 10px 0
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .content-list .content-item {
    display: inline-block;
    padding: 10px 12px;
    background: rgba(0, 0, 0, .2);
    border-radius: 4px;
    border: 1px solid hsla(0, 0%, 100%, .41);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: auto;
    vertical-align: middle;
    margin-right: 20px;
    position: relative
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .content-list .content-item:before {
    content: "";
    position: absolute;
    left: .013333rem;
    width: .013333rem;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    background: #dedede
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .content-list .content-item .title {
    font-size: 14px;
    color: #fff;
    line-height: 1.2;
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .content-list .content-item .content {
    margin-top: 4px;
    font-size: 14px;
    color: #cacaca;
    line-height: 1.2;
    display: flex;
    align-items: center
  }

  .company-intro-page #development .dev-list-wrap .dev-list-item .content-list .content-item .content:before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 2px;
    margin-right: 3px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #cacaca
  }

  .about-culture {
    background: #f3f6f8;
    overflow: hidden;
  }

  .culture {
    padding: 0 3.125vw;
    padding-top: 3.75vw;
    padding-bottom: 2vw;
  }

  .culture-content {
    width: 100%;
    padding: 0 1%;
    font-size: 0;
    margin: 7vw 0;
    text-align: center;
  }

  .culture-li {
    width: 25%;
    height: 25%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    z-index: 2 !important;
  }

  .culture-li>img {
    width: 100%;
  }

  .culture-div {
    width: 106%;
    height: 106%;
    position: absolute;
    left: -3%;
    top: -3%;
    background: #fff;
    border-radius: 100%;
    text-align: center;
    padding: 25% 10%;
  }

  .culture-li:nth-child(2n + 0) {
    z-index: 1 !important;
  }

  .culture-li:nth-child(2n + 0) .culture-div {
    background: #fdfdfe;

  }

  .culture-a {
    height: 4.6875vw;
    font-size: 0;
  }

  .culture-a img {
    width: 4.6875vw;
    height: 100%;
  }

  .culture-a .img2 {
    display: none;
  }

  .culture-b {
    margin: .6vw 0;
  }

  .culture-e {
    margin: .2vw 0;
  }

  .culture-li:hover .culture-a .img1 {
    display: none;
  }

  .culture-li:hover .culture-a .img2 {
    display: inline-block;
  }

  .culture-li:hover .culture-b {
    font-weight: bold;
  }

  .culture-li:hover {
    /* scale: 1.1; */
  }

  .about-honorary {
    background: #fff;
    overflow: hidden;
  }

  .honorary {
    padding: 0 3.125vw;
    padding-top: 3.75vw;
    padding-bottom: 2vw;
  }

  .honorary-content {
    display: flex;
    justify-content: center;
  }

  .honorary-swiper {
    width: 100%;
    height: 500px;
    color: #000;
  }

  .honorary-swiper .swiper {
    width: 100%;
    margin: 0 auto;
    background: #fff;
    position: relative;
  }

  ::v-deep .el-carousel__container {
    height: 500px;
  }

  .carouses-content {
    display: flex;
    margin: 40px 10px 40px 55px;
  }

  .contents-item {
    width: 22%;
    height: 400px;
    background: #f3f3f3;
    margin: 1%;
    margin-bottom: 34px;
  }

  .contents-item:hover {
    box-shadow: 0 0 10px #ddd;
    transition-duration: 0.3s;
  }

  .item-image {
    display: flex;
    align-items: center;
    padding: 0;
    height: 290px;
    margin: 0 auto;
    background: #fff;
    background-size: contain;
  }

  .item-image img {
    width: 100%;
    height: 290px;
    object-fit: contain;
  }

  .contents-p {
    color: #414141;
    font-size: 16px;
    font-weight: bold;
    height: 27px;
    margin-top: 38px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    text-align: center;
  }

  .about-partner {
    background: #f3f6f8;
    overflow: hidden;
  }

  .partner {
    padding: 0 3.125vw;
    padding-top: 3.75vw;
    padding-bottom: 2vw;
  }

  .partner-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2.2vw;
    width: 100%;
    font-size: 0;
  }

  .partner-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    height: 9vw;
    border-radius: 100%;
    overflow: hidden;
    text-align: center;
    margin-right: 1.65%;
    margin-bottom: 1.65%;
    vertical-align: top;
    background: #fff;
  }

  .partner-item img {
    min-height: 15%;
    min-width: 15%;
    max-width: 75%;
    max-height: 75%;
  }

  .partner-more {
    display: flex;
    justify-content: center;
    margin: 1.5vw 0;
  }

  .morebut {
    display: inline-block;
    width: 9.375vw;
    line-height: 3.125vw;
    background: #fff;
    border-radius: 1.6vw;
    position: relative;
    color: #222;
    padding: 0 1.8vw;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    transition: all .5s;
    -webkit-transition: all .5s;
  }

  .morebut>span {
    position: relative;
    z-index: 2;
  }

  .morebut>i {
    width: .4vw;
    height: .4vw;
    background: #00AAc3;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    right: 1.8vw;
    z-index: 1;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transition: all .7s;
    -webkit-transition: all .7s;
  }

  .morebut:hover {
    color: #fff;
    font-weight: bold;
    transition: all .5s;
    -webkit-transition: all .5s;
  }

  .morebut:hover>i {
    width: 16vw;
    height: 16vw;
    right: -2.3vw;
    transition: all .7s;
    -webkit-transition: all .7s;
  }

  .partner-but {
    border: 1px solid #ccc;
  }
</style>