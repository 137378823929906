<template>
  <div id="app">
    <router-view />
    <div class="back-to-top" v-if="scrollTop > 200" @click="backToTop">
      <img src="https://oss.hzwohu.com/assets/icon/back.png" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        scrollTop: 0,
      };
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
      handleScroll() {
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      },

      backToTop() {
        const duration = 500 // 动画持续时间
        const startPos = this.scrollTop // 当前滚动位置
        const startTime = performance.now() // 开始时间

        const animateScroll = (timestamp) => {
          const elapsed = timestamp - startTime // 已经过去的时间
          const progress = Math.min(elapsed / duration, 1) // 完成百分比
          const easeProgress = 0.5 - 0.5 * Math.cos(progress * Math.PI) // 缓动效果的百分比

          window.scrollTo(0, startPos * (1 - easeProgress)) // 滚动到顶部

          if (progress < 1) {
            requestAnimationFrame(animateScroll)
          }
        }

        requestAnimationFrame(animateScroll)
      },
    }

  }
</script>

<style>
  [v-cloak] {
    display: none;
  }

  @font-face {
    font-family: Alibaba-PuHuiTi-Medium;
    src: url('https://oss.hzwohu.com/assets/font/AlibabaPuHuiTi-3-65-Medium.woff'),
  }

  @font-face {
    font-family: Alibaba-PuHuiTi-Light;
    src: url('https://oss.hzwohu.com/assets/font/Alibaba-PuHuiTi-Light.woff'),
  }

  @font-face {
    font-family: Alibaba-PuHuiTi-Regular;
    src: url('https://oss.hzwohu.com/assets/font/Alibaba-PuHuiTi-Regular.woff'),
  }

  .fm-APT-R {
    font-family: Alibaba-PuHuiTi-Regular;
  }

  .fm-APT-L {
    font-family: Alibaba-PuHuiTi-Light;
  }

  .fm-APT-M {
    font-family: Alibaba-PuHuiTi-Medium;
  }

  #app {
    /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
    /* text-align: center;
  color: #2c3e50; */
  }

  body {
    position: relative;
    width: 100%;
    font-size: 14px;
    color: #222;
    line-height: 1.6;
    font-family: PingFangSC-Regular, helvetica neue, tahoma, PingFang SC, microsoft yahei, arial, hiragino sans gb, sans-serif;
  }

  ul {
    padding-left: 0px;
    margin-bottom: 0px;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .back-to-top {
    position: fixed;
    right: 30px;
    top: calc(100vh - 110px);
    color: #fff;
    cursor: pointer;
    z-index: 99;
  }

  .back-to-top img {
    width: 60px;
    height: 60px;
  }

  .back-to-top:hover {
    opacity: 1;
  }
  html {
    scroll-padding-top: 5vw;
}
</style>