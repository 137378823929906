<template>
  <div>
    <header ref="headers" :class="{ 'bj': isHeaderFixed }">
      <div class="header wid1800">
        <div class="logo">
          <img src="https://oss.hzwohu.com/assets/logo.png" alt="logo">
        </div>
        <div class="header-nav">
          <div class="header-nav-li " @click="goIndex">首页</div>
          <div class="header-nav-li" @click="goAbout">关于沃虎</div>
          <div class="header-nav-li on">公司业务</div>
          <div class="header-nav-li" @click="goJon">联系我们</div>
          <div class="header-nav-li header-nav-join">
            加入我们
            <div class="header-b header-k jianli">
              <div class="header-k2 fnt36 fm-APT-R">
                简历投递请联系
              </div>
              <div class="header-k3 fm-APT-R" style="font-size:15px;">
                HRBP 涂女士：18106511396（手机和微信同号）<br>
                <a href="https://www.zhipin.com/gongsi/job/0abc679d53ebe2151nV72dW8EA~~.html?ka=all-jobs-hot" target="_blank">人才通道</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="home-top">
      <swiper :options="swiperOptions" ref="mySwipers">
        <swiper-slide class="business-sd">
          <div class="home-slide-common home-slide1">
            <div class="business-div">
              <div class="business-lt">
                <div class="business-a fm-APT-L fnt20">以科技创新赋能品牌营销</div>
                <div class="business-b fm-APT-M fnt50">
                  品牌营销<br>
                </div>
              </div>
              <div class="business-rt fnt28 fm-APT-R">
                <p><span style="font-size: 15px;"><span
                      style="font-size: 15px; white-space: normal;">品牌营销是将品牌价值和营销策略相结合的活动。它不仅关注产品的功能和特点，还着重强调品牌的独特性、形象和文化。
                    </span>沃虎科技通过云图、数据银行、京东数坊、飞瓜等多方数据平台，以数据为底层，塑造品牌形象、传递品牌价值，增强品牌认知度和影响力，从而吸引更多目标消费者，提高品牌忠诚度，增加市场份额和竞争优势。陪跑多个新锐品牌，相携四年，从诞生到月入破亿、助力多个品牌生意增长。</span>
                </p>
                <p><br></p>
              </div>
            </div>

          </div>
        </swiper-slide>
        <swiper-slide class="business-sd">
          <div class="home-slide-common home-slide2">
            <div class="business-div">
              <div class="business-lt">
                <div class="business-a fm-APT-L fnt20">整合海量媒介资源，助力品牌高效触达目标受众</div>
                <div class="business-b fm-APT-M fnt50">
                  媒介资源<br>
                </div>
              </div>
              <div class="business-rt fnt28 fm-APT-R">
                <p><span style="font-size: 15px;"><span
                      style="font-size: 15px; white-space: normal;">媒介资源-专业媒介服务团队，成熟、专业的服务团队全流程管控保障机制提供一站式全国媒介流量采买及媒介服务。</span>了解客户相关需求，输出诊断报告；分析媒体资源，选择适配媒体；专业、严谨执行质量管控机制，以数据为指导提升投放效率。</span>
                </p>
                <p><br></p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="business-sd">
          <div class="home-slide-common home-slide3">
            <div class="business-div">
              <div class="business-lt">
                <div class="business-a fm-APT-L fnt20">电商运营专家，助力企业打造爆款产品，实现销量增长</div>
                <div class="business-b fm-APT-M fnt50">
                  电商运营<br>
                </div>
              </div>
              <div class="business-rt fnt28 fm-APT-R">
                <p><span style="font-size: 15px;"><span
                      style="font-size: 15px; white-space: normal;">沃虎科技拥有成熟和稳定团队，核心管理层团队从业经验5+年，丰富专业主播矩阵、模特资源，4000m^2自有直播基地，-
                      精细化运营工具-平台工具，沃虎独有应用工具，策略研究工具。</span>沃虎科技运营服务内容：店铺运营、直播服务、仓储物流、消费者运营、CRM、广告投放。</span>
                </p>
                <p><br></p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="business-sd">
          <div class="home-slide-common home-slide4">
            <div class="business-div">
              <div class="business-lt">
                <div class="business-a fm-APT-L fnt20">赋能IP孵化，打造爆款IP</div>
                <div class="business-b fm-APT-M fnt50">
                  IP孵化<br>
                </div>
              </div>
              <div class="business-rt fnt28 fm-APT-R">
                <p><span style="font-size: 15px;"><span
                      style="font-size: 15px; white-space: normal;">沃虎科技挖掘极具潜力的达人，通过成熟完善的IP孵化体系，联合资深孵化团队共同打造行业顶级的达人。</span></span>
                </p>
                <p><br></p>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination" slot="pagination"></div>
    </div>
    <Footers></Footers>
  </div>
</template>

<script>
  import $ from 'jquery'; // 引入jQuery库
  import Footers from "@/components/footers";
  import {
    swiper,
    swiperSlide
  } from "vue-awesome-swiper"

  export default {
    components: {
      Footers,
      swiper,
      swiperSlide,
    },
    data() {
      return {
        visiblePartners: [],
        showMoreButton: true,
        partnersPerLoad: 8,
        currentIndex: 0,
        scrollDistance: 0,
        isHeaderFixed: false,
        swiperOptions: {
          autoplay: false,
          autoplayDisableOnInteraction: false,
          preventLinksPropagation: true,

          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets',
          },
          mousewheel: { // 鼠标滚动切换slide的设置（可选）
            releaseScroll: true, // 允许鼠标滚动切换slide（默认是禁用）
            sensitivity: 1 // 设置滚动灵敏度（可选）
          },
          autoplayDisableOnInteraction: false,
          onTransitionEnd: function (swiper1) {
            // if (swiper1.progress == 1) {
            //   swiper1.activeIndex = swiper1.slides.length - 1;
            // }
          },
          autoHeight: true, //高度随内容变化
          // effect: 'cube',
          direction: 'vertical',
        }
      };
    },
    created() {},
    mounted() {
      const $headerNavLi2 = $('.header-nav-join');
      const $headerB = $headerNavLi2.find('.header-b');
      // 使用jQuery绑定事件
      $headerNavLi2.hover(
        function () {
          $headerB.stop().slideDown();
        },
        function () {
          $headerB.stop().slideUp();
        }
      );
      new this.$wow.WOW().init({ //新建实列
        boxClass: 'wow', //class名字
        animateClass: 'animated', //animateclass动画库类名,和当前animate动画库决定
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true //对异步加载的内容进行操作（默认为true）
      });
      window.addEventListener('scroll', this.handleScroll);
      const slideIndex = parseInt(this.$route.query.slideIndex) || 0;
      this.$nextTick(() => {
        if (this.$refs.mySwipers) {
          this.$refs.mySwipers.swiper.slideTo(slideIndex, 1000, false)

        }
      });
    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      goAbout() {
        this.$router.push("/about");
      },
      goJon() {
        this.$router.push("/join");
      },
      goBusiness() {
        this.$router.push("/business");
      },
      goIndex() {
        this.$router.push("/");
      },
      handleAnchorClick(anchor) {
        this.$el.querySelector(anchor).scrollIntoView({
          behavior: 'smooth'
        });
      },
      handleScroll() {
        this.scrollDistance = window.scrollY || document.documentElement.scrollTop;
        if (this.scrollDistance > 10) {
          this.isHeaderFixed = true;
        } else {
          this.isHeaderFixed = false;
        }
      },
    },

  };
</script>
<style scoped>
  .swiper-container {
    height: 100vh;
  }

  .swiper-pagination {
    right: 40px;
    top: 50%;
    transform: translate3d(0, -50%, 0)
  }

  ::v-deep .swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex: 0 0 auto;
    flex-shrink: 0;
    width: 100%;
    height: auto;
    position: relative
  }

  ::v-deep .swiper-pagination-bullet {
    display: block;
    width: .8vw;
    height: .8vw;
    border: 1px solid #fff;
    background: initial;
    margin: .8vw 0;
    opacity: .6;
  }

  ::v-deep .swiper-pagination-bullet-active {
    background: #00AAc3;
    border-color: #00AAc3;
    opacity: 1;
  }

  .home-slide-common {
    width: 100%;
    height: 100vh;
    background: no-repeat center;
    background-size: 100% 100%;
  }

  .home-slide1 {
    background: url('https://oss.hzwohu.com/assets/yingxiao.jpg') no-repeat;
    background-size: 100% 100%;
  }

  .home-slide2 {
    background: url('https://oss.hzwohu.com/assets/meijie.jpg') no-repeat;
    background-size: 100% 100%;
  }

  .home-slide3 {
    background: url('https://oss.hzwohu.com/assets/dianshang.jpg') no-repeat;
    background-size: 100% 100%;
  }

  .home-slide4 {
    background: url('https://oss.hzwohu.com/assets/ips.jpg') no-repeat;
    background-size: 100% 100%;
  }

  .home-slide2 div {
    visibility: visible !important;
  }

  .home-slide-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .home-slide-text p {
    font-size: 20px;
    color: #fff;
    font-weight: 700;
  }

  /* .wow {
    visibility: hidden;
  } */

  .header-k {
    width: 52vw;
    left: initial;
    right: 3.125vw;
    background: #fff;
    padding: 2vw 2.3vw;
    overflow: hidden;
  }

  .header-k2 {
    float: left;
    line-height: 1.1;
    padding-bottom: 1.2vw;
    position: relative;
    color: #222;
  }

  .header-b {
    width: 93.75%;
    border-radius: 10px;
    position: absolute;
    top: 100%;
    overflow: hidden;
    display: none;
  }

  .jianli {

    width: 45%;
    display: none;
    height: 112px;
    padding-top: 28.8px;
    margin-top: 0px;
    padding-bottom: 28.8px;
    margin-bottom: 0px;
    box-shadow: 0 5px 10px #ccc;
    font-weight: normal !important;
  }

  .fnt36 {
    font-size: 1.875vw;
  }

  .header-k3 {
    float: right;
    /* text-align: right; */
    line-height: 1.2;
    color: #222;
  }

  .header-k3 a {
    display: inline-block;
    margin-top: 15px;
  }

  .fm-APT-R {
    font-family: Alibaba-PuHuiTi-Regular;
  }

  .fnt30 {
    font-size: 1.5625vw;
  }

  .bj {
    background: #fff;
    border-bottom: 1px solid #ccc;
  }

  header.bj .header-nav {
    color: #222;
    line-height: 5vw;
  }

  header {
    position: fixed;
    z-index: 39;
    width: 100%;
    top: 0;
    left: 0;
  }

  .header {
    transition: all .3s;
    -webkit-transition: all .3s;
  }

  .wid1800 {
    width: 93.75%;
    margin: 0 auto;
  }

  .logo {
    font-size: 0;
    float: left;
    transition: all .3s;
    -webkit-transition: all .3s;
  }

  .logo img {
    width: 10vw;
  }

  .header-nav {
    float: right;
    font-size: 0;
    color: #fff;

  }

  .header-nav-li {
    display: inline-block;
    vertical-align: top;
    vertical-align: top;
    font-size: 0.94vw;
    font-weight: 700;
    line-height: 5vw;
    padding: 0 1.5vw;
    cursor: pointer;
    transition: all .5s;
    -webkit-transition: all .5s;
  }

  .header-nav-li:hover,
  .header-nav-li.on {
    opacity: 1 !important;
    color: #00AAc3 !important;
    transition: all .5s;
    -webkit-transition: all .5s;
  }

  .about-content {
    width: 100%;
    height: 100vh;
    min-height: 45vw;
    position: relative;
    overflow: hidden;
  }

  .home-top {
    width: 100%;
    height: 100vh;
    min-height: 45vw;
    position: relative;
    overflow: hidden;
  }

  .home-custom {
    padding: 4vw 0;
  }

  .custom-ac {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .gybt-xt {
    display: inline-block;
    line-height: 1;
    padding-bottom: .5vw;
    border-bottom: 2px solid #00AAc3;
  }

  .custom-ab {
    display: inline-block;
  }

  .custom-a {
    line-height: 1;
  }

  .fnt28 {
    font-size: 1.4583vw;
  }

  .fnt20 {
    font-size: 1.04166vw;
  }

  .fnt50 {
    font-size: 2.6041666vw;
  }

  .course-but {
    display: flex;
    align-items: center;
  }

  .business-div {
    width: 90%;
    padding: 0 3.125%;
    padding-bottom: 4vw;
    position: absolute;
    left: 100px;
    bottom: 100px;
    color: #fff;
    z-index: 3;
  }

  .business-lt {
    width: 32%;
    line-height: 1.2;
    float: left;
    position: relative;
    top: 80px;
    opacity: 0;
  }

  .business-a {
    margin-bottom: .5vw;
  }

  .business-rt {
    width: 45%;
    float: right;
    line-height: 1.4;
    padding: .7vw 0;
    position: relative;
    top: 80px;
    opacity: 0;
  }

  .business-sd.swiper-slide-active .business-lt {
    opacity: 1;
    top: 0;
    transition: all .8s .8s;
    -webkit-transition: all .8s .8s;
  }

  .business-sd.swiper-slide-active .business-rt {
    opacity: 1;
    top: 0;
    transition: all .8s 1.4s;
    -webkit-transition: all .8s 1.4s;
  }
</style>