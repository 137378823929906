<template>
  <div style="background:#f2f2f2;">
    <header ref="headers" :class="{ 'bj': isHeaderFixed }">
      <div class="header wid1800">
        <div class="logo">
          <img src="https://oss.hzwohu.com/assets/logo.png" alt="logo">
        </div>
        <div class="header-nav">
          <div class="header-nav-li" @click="goIndex">首页</div>
          <div class="header-nav-li" @click="goTopage('about')">关于沃虎</div>
          <div class="header-nav-li" @click="goTopage('business')">公司业务</div>
          <div class="header-nav-li" @click="goTopage('join')">联系我们</div>
          <div class="header-nav-li header-nav-join">
            加入我们
            <div class="header-b header-k jianli">
              <div class="header-k2 fnt36 fm-APT-R">
                简历投递请联系
              </div>
              <div class="header-k3 fm-APT-R" style="font-size:15px;">
                HRBP 涂女士：18106511396（手机和微信同号）<br>
                <a href="https://www.zhipin.com/gongsi/job/0abc679d53ebe2151nV72dW8EA~~.html?ka=all-jobs-hot" target="_blank">人才通道</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="join-content">
      <div class="imgBj pos pc-show">
      </div>
      <div class="join-text">
        <div class="p1">客户案例</div>
        <div class="p2">案例精粹，服务无限，品牌成就共赢</div>
      </div>
      <i class="gd"> <img src="https://oss.hzwohu.com/assets/banner/gd.png" alt=""> </i>
    </div>
    <!-- <div class="join-subtitle wow fadeInUp animated">
      <h2>客户案例</h2>
      <h3><span>Customer Case</span></h3>
    </div>
    <div class="case-content wid1800">
      <div class="case-list">
        <div class="case-list-item">
          <img src="https://oss.hzwohu.com/assets/case/case1.png" alt="">
        </div>
        <div class="case-list-item">
          <img src="https://oss.hzwohu.com/assets/case/case2.png" alt="">
        </div>
        <div class="case-list-item">
          <img src="https://oss.hzwohu.com/assets/case/case3.png" alt="">
        </div>
        <div class="case-list-item">
          <img src="https://oss.hzwohu.com/assets/case/case4.png" alt="">
        </div>
        <div class="case-list-item">
          <img src="https://oss.hzwohu.com/assets/case/case5.png" alt="">
        </div>
      </div>
    </div> -->
    <!-- <div class="case-contents product-detail wid1800">
      <div class="case-card">
        <img src="https://oss.hzwohu.com/assets/case/left2.png" alt="">
      </div>
      <div class="case-fr">
        <img src="https://oss.hzwohu.com/assets/case/left1.png" alt="">
      </div>
    </div> -->
    <div class="case2">
      <div class="wid1800">
        <div class="case4" :class="{ on: chooseIndex === 0 }" @click="getAll">
          全部
        </div>
        <div class="case3">
          <div class="sx"><span></span></div>
          <div class="title">按行业<span>：</span></div>
          <ul>
            <li :class="{ on: chooseIndex === 1 }" @click="getCloth">服饰</li>
            <li :class="{ on: chooseIndex === 2 }" @click="getBeauty">美妆</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="case-contents">
      <div class="wid1800">
        <div class="case-list wow fadeInUp animated">
          <div class="case-list-item" v-for="(item,index) in chooseList" :key="index">
             <el-image  style="height: 350px;" :src="item.url"
            :preview-src-list="[item.url]" :lazy="true">
          </el-image>
            <!-- <img :src="item.url" alt=""> -->
          </div>
        </div>
      </div>
    </div>
    <Footers></Footers>
  </div>
</template>

<script>
  import $ from 'jquery'; // 引入jQuery库
  import Footers from "@/components/footers";
  export default {
    components: {
      Footers,
    },
    data() {
      return {
        chooseIndex: 0,
        visiblePartners: [],
        showMoreButton: true,
        partnersPerLoad: 8,
        currentIndex: 0,
        scrollDistance: 0,
        isHeaderFixed: false,
        chooseList: [],
        caseList: [{
            url: "https://oss.hzwohu.com/assets/case/case4.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_10.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/case2.png",
            type: 'cloth'
          },
         
          {
            url: "https://oss.hzwohu.com/assets/case/case1.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/case5.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_01.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_02.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_03.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_04.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_05.png",
            type: 'beauty'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_06.png",
            type: 'beauty'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_07.png",
            type: 'cloth'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_08.png",
            type: 'beauty'
          },
          {
            url: "https://oss.hzwohu.com/assets/case/onepage_09.png",
            type: 'cloth'
          },
        ],
      };
    },
    created() {
      this.chooseList = this.caseList;
      // this.loadPartners();
    },
    mounted() {
      const $headerNavLi2 = $('.header-nav-join');
      const $headerB = $headerNavLi2.find('.header-b');

      // 使用jQuery绑定事件
      $headerNavLi2.hover(
        function () {
          $headerB.stop().slideDown();
        },
        function () {
          $headerB.stop().slideUp();
        }
      );
      new this.$wow.WOW().init({ //新建实列
        boxClass: 'wow', //class名字
        animateClass: 'animate__animated', //animateclass动画库类名,和当前animate动画库决定
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true //对异步加载的内容进行操作（默认为true）
      });
      window.addEventListener('scroll', this.handleScroll);

    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      getAll() {
        this.chooseIndex = 0;
        this.chooseList = this.caseList;
      },
      getCloth() {
        this.chooseIndex = 1;
        this.chooseList = this.caseList.filter(item => item.type === 'cloth');

      },
      getBeauty() {
        this.chooseIndex = 2;
        this.chooseList = this.caseList.filter(item => item.type === 'beauty');
        console.log("this.chooseList",this.chooseList)

      },

      goTopage(val) {
        this.$router.push(`/${val}`);
        window.scrollTo(0, 0);
      },
      goIndex() {
        this.$router.push("/");
        window.scrollTo(0, 0);

      },
      handleAnchorClick(anchor) {
        this.$el.querySelector(anchor).scrollIntoView({
          behavior: 'smooth'
        });
      },
      handleScroll() {
        this.scrollDistance = window.scrollY || document.documentElement.scrollTop;
        if (this.scrollDistance > 10) {
          this.isHeaderFixed = true;
        } else {
          this.isHeaderFixed = false;
        }
      },
    },

  };
</script>
<style scoped>
  .header-k {
    width: 52vw;
    left: initial;
    right: 3.125vw;
    background: #fff;
    padding: 2vw 2.3vw;
    overflow: hidden;
  }

  .header-k2 {
    float: left;
    line-height: 1.1;
    padding-bottom: 1.2vw;
    position: relative;
    color: #222;
  }

  .header-b {
    width: 93.75%;
    border-radius: 10px;
    position: absolute;
    top: 100%;
    overflow: hidden;
    display: none;
  }

  .jianli {
    width: 45%;
    display: none;
    height: 112px;
    padding-top: 28.8px;
    margin-top: 0px;
    padding-bottom: 28.8px;
    margin-bottom: 0px;
    box-shadow: 0 5px 10px #ccc;
    font-weight: normal !important;
  }

  .fnt36 {
    font-size: 1.875vw;
  }

  .header-k3 {
    float: right;
    /* text-align: right; */
    line-height: 1.2;
    color: #222;
  }

  .header-k3 a {
    display: inline-block;
    margin-top: 15px;
  }

  .fm-APT-R {
    font-family: Alibaba-PuHuiTi-Regular;
  }

  .fnt30 {
    font-size: 1.5625vw;
  }

  .bj {
    background: #fff;
    border-bottom: 1px solid #ccc;
  }

  header.bj .header-nav {
    color: #222;
    line-height: 5vw;
  }

  header {
    position: fixed;
    z-index: 39;
    width: 100%;
    top: 0;
    left: 0;
  }

  .header {
    transition: all .3s;
    -webkit-transition: all .3s;
  }

  .wid1800 {
    width: 93.75%;
    margin: 0 auto;
  }

  .logo {
    font-size: 0;
    float: left;
    transition: all .3s;
    -webkit-transition: all .3s;
  }

  .logo img {
    width: 10vw;
  }

  .header-nav {
    float: right;
    font-size: 0;
    color: #fff;

  }

  .header-nav-li {
    display: inline-block;
    vertical-align: top;
    vertical-align: top;
    font-size: 0.94vw;
    font-weight: 700;
    line-height: 5vw;
    padding: 0 1.5vw;
    cursor: pointer;
    transition: all .5s;
    -webkit-transition: all .5s;
  }

  .header-nav-li:hover,
  .header-nav-li.on {
    opacity: 1 !important;
    color: #00AAc3 !important;
    transition: all .5s;
    -webkit-transition: all .5s;
  }

  .about-content {
    width: 100%;
    height: 100vh;
    min-height: 45vw;
    position: relative;
    overflow: hidden;
  }

  .banner-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url('https://oss.hzwohu.com/assets/banner/lianxi.jpeg') no-repeat;
    background-size: cover;
  }

  .join-content {
    width: 100%;
    height: 539px;
    position: relative;
    overflow: hidden;
  }

  .imgBj {
    background: no-repeat center;
    background-size: cover;
    background-image: url('https://oss.hzwohu.com/assets/case/bg.png');
  }

  .join-text {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
  }

  .join-text .p1 {
    color: #fff;
    font-size: 40px;
    line-height: 1.4;
    font-weight: 700;
  }

  .join-text .p2 {
    color: rgba(255, 255, 255, 0.9);
    font-size: 24px;
    letter-spacing: 2px;
  }

  .imgBj.pos {
    width: 100%;
    height: 539px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .gd {
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
    animation: dong3 1.5s linear infinite;
  }

  .gd img {
    width: 24px;
  }

  @keyframes dong3 {
    0% {
      transform: translate(-50%, 0);
    }

    50% {
      transform: translate(-50%, 15px);
    }

    100% {
      transform: translate(-50%, 0);
    }
  }

  .join-subtitle h2 {
    font-size: 40px;
    color: #111;
    text-align: center;
    margin: 80px auto 15px;
    font-weight: normal;
  }

  .join-subtitle h3 {
    font-size: 40px;
    color: #111111;
    margin-bottom: 60px;
    text-align: center;
    font-weight: normal;
  }

  .join-subtitle h3 span {
    position: relative;
    display: inline-block;
  }

  .join-subtitle h3 span:after {
    content: '';
    background-color: #e2e2e2;
    width: 230px;
    height: 1px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -27px;
  }

  .case-content {
    margin-top: 20px;
    text-align: center;
  }

  .case-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .case-list-item {
    margin: 20px;
    cursor: pointer;
  }

  .case-list-item:hover {
    /* transform: scale(1.1); */
  }

  .case-list-item img {
    height: 350px;
    box-shadow: 3px 2px 26px 0 rgba(0, 0, 0, 0.07);
  }

  .case-card {
    box-shadow: 3px 2px 26px 0 rgba(0, 0, 0, 0.07);
    width: 48%;
    padding-bottom: 40px;
    position: absolute;
    z-index: 10;
    left: 0;
    top: 40px;
  }

  .case-card img {
    width: 100%;
  }

  /* .product-detail {
    margin: 105px auto 150px;
    padding-top: 125px;
    background-size: 20%;
    background: url('https://oss.hzwohu.com/assets/case/num_01.png') no-repeat;
    background-position: 95% 0;
    background-size: 20%;
  } */

  .case-fr {
    width: 70%;
    float: right;
  }

  .case-fr img {
    width: 100%;
    height: 400px;
    display: block;
  }

  .case2 {
    width: 100%;
    overflow: hidden;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 3;
    padding: 9px 0px;
  }

  .case4,
  .case3 {
    float: left;
  }

  .case4 {
    font-size: 14px;
    color: #2E2F34;
    line-height: 40px;
    cursor: pointer;
  }

  .case3 .sx {
    float: left;
    padding: 11px 0px;
    margin: 0 40px;
  }

  .case3 .sx span {
    width: 1px;
    height: 18px;
    background: #E3E3E3;
    display: block;
  }

  .case3 .title {
    float: left;
    font-size: 15px;
    color: #7B7D85;
    line-height: 40px;
  }

  .case3 .title span {
    font-size: 15px;
  }

  .case3 .title.on span {
    font-size: 15px;
  }

  .case3 ul {
    float: left;
    list-style: none;
  }

  .case3 ul li {
    float: left;
    margin-right: 35px;
  }

  .case3 ul li:last-child {
    margin-right: 0px;
  }

  .case3 ul li {
    font-size: 15px;
    color: #2E2F34;
    line-height: 40px;
    cursor: pointer;
  }

  .case4:hover,
  .case4.on {
    color: #00AAc3;
  }

  .case-contents {
    background: #F5F6FA;
    padding: 70px 0px 80px;
  }

  .case3 ul li:hover, li.on{
    color: #00AAc3 !important;
  }
</style>